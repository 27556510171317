export default {
  namespaced: true,
  state: {
    product: null,
    cylinder_serial: null,
    lastComplaint: 0,
  },
  mutations: {
    setProduct(state, data) {
      state.product = data;
    },
    setSerial(state, data) {
      state.cylinder_serial = data;
    },

    reset(state) {
      state.product = null;
      state.cylinder_serial = "";
    },
  },
  getters: {},
  actions: {
    createProduct({ getters, commit }, data) {
      return new Promise((resolve, reject) => {
        if (data.cylinder && getters["getByCylinderId"](data.cylinder.cylinder_id)) {
          reject("Bulta już posiada reklamacje!");
        }
        let obj = {
          complaint_number: "0",
          cylinder: data.cylinder,
          product: data.product,
          order: data.order,
          cylinder_id: data.cylinder ? data.cylinder.cylinder_id : 0,
          product_id: data.product ? data.product.product_id : 0,
          checkboxes: [],
          description: "",
        };
        commit("setCreateComplaint", obj);
        resolve(obj.complaint_number);
      });
    },

    reset: function ({ commit }) {
      commit("reset");
    },
  },
};
