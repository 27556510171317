<template>
  <div>
    <div class="form-group mb-3">
      <label>Data badania okresowego</label>
      <DatePicker
        @input="emitField"
        format="YYYY-MM-DD"
        value-type="YYYY-MM-DD"
        class="w-100"
        v-model="item.cylinder_legalization_date"
        :class="{ 'is-invalid': validation.cylinder_legalization_date }"
        :input-class="{
          'is-invalid': validation.cylinder_legalization_date,
          'form-control form-control-lg': true,
        }"
      >
      </DatePicker>
      <div class="invalid-feedback">{{ validation.cylinder_legalization_date }}</div>
    </div>

    <div class="form-floating mb-3">
      <input
        placeholder="Nr świadectwa"
        @input="emitField"
        class="form-control form-control-lg"
        type="text"
        :disabled="item.cylinder_id > 0"
        v-model="item.certificate_number"
        :class="{ 'is-invalid': validation.certificate_number }"
      />
      <label>Nr świadectwa</label>
      <div class="invalid-feedback">{{ validation.certificate_number }}</div>
    </div>
    <div class="form-floating mb-3">
      <label>Wybita data następnego badania</label>

      <DatePicker
        @input="emitField"
        format="YYYY-MM"
        value-type="YYYY-MM"
        class="w-100"
        v-model="item.legalization_date"
        :class="{ 'is-invalid': validation.legalization_date }"
        :input-class="{
          'is-invalid': validation.legalization_date,
          'form-control form-control-lg': true,
        }"
      >
      </DatePicker>

      <!-- <input placeholder="Wybita data następnego badania" @input="emitField" class="form-control form-control-lg" type="text" :disabled="item.cylinder_id > 0"
            v-model="item.legalization_date" :class="{ 'is-invalid': validation.legalization_date }">
            <label>Wybita data następnego badania</label> -->
      <div class="invalid-feedback">{{ validation.legalization_date }}</div>
    </div>
    <div class="form-floating mb-3">
      <input
        placeholder="Normy PN-EN"
        @input="emitField"
        class="form-control form-control-lg"
        type="text"
        :disabled="item.cylinder_id > 0"
        v-model="item.pn_number"
        :class="{ 'is-invalid': validation.pn_number }"
      />
      <label>Normy PN-EN</label>
      <div class="invalid-feedback">{{ validation.pn_number }}</div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";

export default {
  components: { DatePicker },
  name: "LegalizationForm",
  props: ["validation", "value"],
  data: function () {
    return {
      item: {},
    };
  },
  mounted() {
    this.loadField();
  },
  watch: {
    value: function () {
      this.loadField();
    },
  },
  methods: {
    loadField: function () {
      this.item = this.value;
    },
    emitField: function () {
      this.$emit("input", this.item);
    },
  },
};
</script>
