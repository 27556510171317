import productionValidator from '@/helpers/productionValidator';
import api from '@/api/myApi'
export default {
    namespaced: true,
    state: {
        cylinders: [],
        lastScanned: {},
        products: [],
        errors: [],
        warnings: [],
        valid: true,
        locationFrom: null,
        locationFromProducts: [],
    },
    mutations: {
        setLocationFromState(state, data) {
            state.locationFromProducts = data;
        },
        cylinderAdd(state, data) {
            state.lastScanned = data;
            const index = state.cylinders.findIndex(object => object.cylinder_id === data.cylinder_id);
            if (index === -1) { state.cylinders.push(data); }
        },
        cylinderReset(state) {
            state.cylinders = [];
        },
        cylinderDel(state, data) {
            const index = state.cylinders.findIndex(object => object.cylinder_id === data.cylinder_id);
            if (index !== -1) { state.cylinders.splice(index, 1); }
        },

        productDel(state, data) {
            const index = state.products.findIndex(object => object.product_id === data.product_id);
            if (index !== -1) { state.products.splice(index, 1); }
        },
        start(state, data) {
            state.start = data
        },
        setOrder(state, data) {
            state.order = data;
        },
        setLocationFrom(state, data) {
            state.locationFrom = data;
        },
        setQuantityFull: function (state, data) {
            let lastItem = state.products.find(it => it.product_id == data.product_id);
            if (lastItem) lastItem.quantityFull = parseInt(data.value) ? parseInt(data.value) : 0;
        },
        setQuantityEmpty: function (state, data) {
            let lastItem = state.products.find(it => it.product_id == data.product_id);
            if (lastItem) lastItem.quantityEmpty = parseInt(data.value) ? parseInt(data.value) : 0;
        },
        addProduct: function (state, item) {
            console.log('add', item);
            if (!state.products.find(it => it.product_id == item.product_id)) {
                item.quantityEmpty = 0;
                item.quantityFull = 0;
                console.log('add', item);
                state.products.push(JSON.parse(JSON.stringify(item)));
            }
        },
        reset(state) {
            state.locationFrom = null;
            state.cylinders = [];
            state.products = [];
            state.locationFromProducts = [];
            state.lastScanned = {};
            state.errors = [];
        },
    },
    getters: {
        isLastScannedProduct: (state) => (id) => {
            return state.lastScanned.product_id == id;
        },
        isLastScannedCylinder: (state) => (id) => {
            return state.lastScanned.cylinder_id == id;
        },
        getCylinder: (state) => (id) => {
            return state.cylinders.find(item => item.cylinder_id == id);
        },
        getProduct: (state) => (id) => {
            return state.products.find(item => item.product_id == id);
        },
        getProductCylinder: (state) => (id) => {
            return state.cylinders.filter(item => item.product_id == id);
        },
        getFullData: (state) => {
            return {
                cylinders: state.cylinders,
                products: state.products,
            }
        },
        isAllCylindersFull: (state) => (id) => {
            let cylinders_count = state.cylinders.filter(item => (item.product_id == id && item.cylinder_status == 'empty')).length;
            return cylinders_count == 0;
        },
        getErrors: (state) => (data) => {
            if (data.cylinder_id) {
                return state.errors.filter(item => item.cylinder_id == data.cylinder_id);
            }
            if (data.product_id) {
                return state.errors.filter(item => item.product_id == data.product_id);
            }
            return state.errors;
        },
        getWarnings: (state) => (data) => {
            if (data.cylinder_id) {
                return state.warnings.filter(item => item.cylinder_id == data.cylinder_id);
            }
            if (data.product_id) {
                return state.warnings.filter(item => item.product_id == data.product_id);
            }
            return state.warnings;
        },
        getBadCylinder: (state) => {
            let ids = state.products.map(item => {
                return item.product_id;
            })
            return state.cylinders.filter(item => !ids.includes(item.product_id));
        },

        countCylinder: (state) => (id) => {
            let cylinders_count = state.cylinders.filter(item => item.product_id == id).length;
            let product = state.products.find(item => item.product_id == id);
            if (product) {
                return parseInt(cylinders_count) + parseInt(product.quantityEmpty) + parseInt(product.quantityFull);
            }
            return 0;
        },
        isInLocation: (state) => (id) => {
            return state.locationFromProducts.find(it => it.product_id == id) !== undefined;
        },

        countEmptyOnLocationFrom: (state) => (id) => {
            let product = state.locationFromProducts.find(item => item.product_id == id);
            if (product) {
                return product.quantityEmpty;
            }
            return 0
        },
        countAllCylinders: (state) => {
            let count = state.cylinders.length;
            state.products.forEach(product => {
                count += parseInt(product.quantityEmpty) + parseInt(product.quantityFull);
            })
            return count;
        },


    },
    actions: {
        reset: function ({ commit }) {
            commit('reset');
        },
        validate({ state, getters, rootState, rootGetters }) {
            return new Promise((resolve, reject) => {
                productionValidator.errors = [];
                productionValidator.warnings = [];

                productionValidator.validateQuantity(state, getters, rootGetters);
                productionValidator.validateCylinder(state, getters, rootState);
                state.valid = productionValidator.errors.length == 0;
                //state.valid = true;
                state.errors = productionValidator.errors;
                state.warnings = productionValidator.warnings;
                if (state.valid) {
                    resolve();
                } else {
                    reject(state.errors);
                }
            })
        },
        loadFromState: function ({ commit, rootState }) {
            api.findLocationStorageState(rootState.location_id).then((response => {
                commit('setLocationFromState', response.data.data)
            }))
        }
    }
}