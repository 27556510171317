<template>
    <input type="number" step="0.001" v-model="input" @change="emitField">
</template>

<script>
export default {
    props: ['value'],
    data: function () {
        return {
            input: 0,
        }
    },
    mounted() {
        this.loadField();
    },
    watch: {
        value: function () {
            this.loadField();
        }
    },
    methods: {
        loadField: function () {
            this.input = (this.value ? this.value : 0) / 1000;
        },
        emitField: function () {
            this.$emit('input', this.input * 1000)
        }
    }
}
</script>