<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">
        <div class="w-100 d-flex justify-content-end align-items-center" style="background: #dadada">
            <router-link :to="'/legalization/' + $route.params.id + '/cylinder'" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Menu legalizacji</h2>
        </div>

        <div class="w-100 p-2 pb-5" style="overflow: auto; flex-grow: 2;">


            <div v-if="!closed" class="navbar bg-light mb-2">
                <div class="container-fluid">
                    <router-link :to="'/legalization/' + $route.params.id + '/edit'" class="navbar-brand"
                        href="#">Ustawienia
                    </router-link>
                </div>
            </div>
            <div class="navbar bg-light mb-2">
                <div class="container-fluid">
                    <a :href="baseUrl + '/blueapp/legalization/' + $route.params.id + '/pdf?access_token=' + token"
                        target="_blank" to="/settings" class="navbar-brand">Pobierz PDF</a>
                </div>
            </div>
            <div v-if="!closed" class="navbar bg-light mb-2">
                <div class="container-fluid">
                    <a href="#" @click.prevent="cancel" class="navbar-brand">Anuluj proces</a>
                </div>
            </div>
            <div v-if="!closed" class="navbar bg-light mb-2">
                <div class="container-fluid">
                    <router-link :to="'/legalization/' + $route.params.id + '/close'" class="navbar-brand" href="#">Zakończ
                        proces</router-link>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import myaxios from '@/api/myaxios';
import api from '@/api/myApi';
export default {
    name: 'AppMenu',
    props: {},
    data: function () {
        return {
            legalization: {}
        }
    },

    computed: {
        token: function () {
            return localStorage.getItem('access_token')
        },
        baseUrl: function () {

            return myaxios.defaults.baseURL;
        },
        closed: function () {
            return this.legalization.legalization_status == 'close'
        },
    },
    methods: {
        cancel: function () {
            let vm = this;
            this.$swal.fire({
                title: 'Czy napewno anulować proces?',
                showCancelButton: true,
                confirmButtonText: 'Tak, chcę!',
                cancelButtonText: `Nie`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    api.cancelLegalization(this.$route.params.id).then(() => {
                        vm.$swal('Anulowano proces legalizacji')
                        vm.$router.push('/legalization');
                    });
                }
            }, () => {
                vm.$swal('Wystąpił błąd')

            });

        },
    },
    mounted() {
        api.getLegalization(this.$route.params.id).then(response => {
            this.legalization = response.data;
        },
            () => {
                this.$swal('Wystąpił błąd')

            })
    }
}
</script>