<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Dodaj butlę</h2>
      <button
        type="button"
        @click="add"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        + Dodaj
      </button>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="form-group w-100 mb-3">
        <label>Wybierz towar:</label>
        <div class="input-group w-100">
          <input
            class="form-control"
            readonly
            :value="product ? product.name : 'Wybierz towar'"
            type="text"
          />
          <router-link
            class="btn btn-outline-primary"
            to="/simpleadd/select"
            >{{ product ? "Zmień towar" : "Wybierz towar" }}</router-link
          >
        </div>
      </div>

      <div class="form-group w-100 mb-3">
        <label>Numer seryjny:</label>
        <div class="input-group w-100">
          <input
            class="form-control"
            v-model="serial"
            type="text"
          />
        </div>
      </div>
      <div class="form-group w-100 mb-3">
        <label>Data legalizacji:</label>
        <div class="input-group w-100">
          <Datepicker
            locale="pl"
            input-class="form-control"
            class="w-100"
            v-model="date"
            type="month"
            format="YYYY-MM"
            value-type="YYYY-MM-30"
          />
        </div>
      </div>
      <div class="mt-5 w-100">
        <h5>Ostatnie dodane butle:</h5>
        <div class="w-100">
          <div
            v-for="(item, key) in last"
            :key="key"
            class="border-bottom w-100 p-3 rounded fs-5"
          >
            <router-link :to="'/cylinder/qrcode/' + item">
              {{ item }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue2-datepicker/locale/pl";
import myApi from "@/api/myApi";
import Datepicker from "vue2-datepicker";
export default {
  name: "SimpleaddIndex",
  components: { Datepicker },
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      date: "",
      loading: false,
      showAll: false,
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
  },
  methods: {
    pushToLast: function (code) {
      const data = localStorage.getItem("last_simpleadd");
      let arr = [];
      if (data) {
        arr = JSON.parse(data);
      }
      const newData = [code].concat(arr);
      localStorage.setItem("last_simpleadd", JSON.stringify(newData.splice(0, 5)));
    },

    add: function () {
      let vm = this;
      if (!this.serial) {
        this.$swal("Numer seryjny butli jest wymagany!");
        return;
      }
      if (!this.product) {
        this.$swal("Wybierz produkt!");
        return;
      }

      if (!this.date) {
        this.$swal("Data legalizacji jest wymagana!");
        return;
      }

      this.$swal({
        title: "Chcesz dodać butlę?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Tak, dodaj!",
      }).then((response) => {
        if (response.isConfirmed) {
          myApi
            .postSimpleadd({
              product_id: this.product.product_id,
              cylinder_serial_number: this.serial,
              location_id: this.$store.state.location_id,
              cylinder_legalization_date: this.date,
            })
            .then(
              (r) => {
                console.log(r);
                vm.pushToLast(r.data.data.cylinder_qr_code);
                vm.$router.push("/simpleadd/show/" + r.data.data.cylinder_qr_code);
                vm.serial = "";
              },
              () => {
                vm.$swal("Nie można było dodać butli");
              }
            );
        }
      });
    },
  },
  created() {},
  mounted() {
    const data = localStorage.getItem("last_simpleadd");
    if (data) {
      this.last = JSON.parse(data) ?? [];
    }
  },
};
</script>
