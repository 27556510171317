<template>
    <div>Login</div>
</template>
<script>

export default {
    name: 'LoginView',
    components: {
    },
    props: {
    },
    computed: {

    },
    methods: {
    },
    mounted() {
        //this.getConfig();
        // if(! this.$auth.isAuthenticated()){
        //   alert('musisz sie zalogowac');
        // }else{
        //   alert('jestes zalogowany');
        // }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>