import axios from 'axios';

const authAxios = axios.create({
    baseURL: process.env.VUE_APP_OAUTH2_BASE_URL
});


authAxios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
authAxios.defaults.timeout = 10000;

authAxios.getToken = function () {
    return this.post('/api/oauth/token', {
        'grant_type': 'client_credentials',
        //'scope':'AlterAuto',
        'client_id': localStorage.client_id,
        'client_secret': localStorage.client_secret,
    })
}

export default authAxios;