<template>
    <div>
        <button v-if="!custom" class="fs-2 btn btn-outline-secondary w-100" @click="openModal">Podpisz się</button>
        <div @click="openModal">
            <slot v-if="custom"></slot>
        </div>
        <div v-show="scanning" class="signature_fixed">
            <canvas id="signatureCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
            <div class="makeSignatureInfo">Podpisz tutaj</div>
            <div class="scanbuttons w-100 row p-0 m-0">
                <div class="col-4 p-1">
                    <button @click="closeModal" class="btn btn-secondary w-100 fs-1 border-0">Anuluj</button>
                </div>
                <div class="col-4 p-1">
                    <button @click="clearCanvas" class="btn btn-warning w-100 fs-1 border-0">Czyść</button>
                </div>
                <div class="col-4 p-1">
                    <button :disabled="emptyCanvas" @click="save"
                        class="btn btn-success w-100 fs-1 border-0">OK</button>
                </div>
                <!--                 <button class="btn btn-warning w-50 fs-1 border-0">Podaj kod ręcznie</button>
 -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SignatureGetter',
    props: {
        custom: Boolean,
    },
    data: function () {
        return {
            code: '',
            listener: null,
            scanning: false,
            context: null,
            isDrawing: false,
            canvas: null,
            canvasWidth: window.screen.width - 10,
            canvasHeight: window.screen.height - 10,
            emptyCanvas: true,
        }
    },
    computed: {

    },
    watch: {},
    methods: {

        makeBodyNoscalable: function () {
            var html = document.querySelector("html");

            html.classList.add('drawing');

        },
        makeBodyNormal: function () {
            var html = document.querySelector("html");
            html.classList.remove('drawing');
        },
        touchstart: function (coors) {
            //console.log(this);
            this.context.beginPath();
            this.context.moveTo(coors.x, coors.y);
            this.isDrawing = true;
        },
        touchmove: function (coors) {
            if (this.isDrawing) {
                this.context.lineTo(coors.x, coors.y);
                this.context.stroke();
                this.emptyCanvas = false;
            }
        },
        touchend: function (coors) {
            if (this.isDrawing) {
                this.touchmove(coors);
                this.isDrawing = false;
            }
        },
        clearCanvas: function () {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.emptyCanvas = true;
        },
        preventEvent: function (e) {
            e.preventDefault();
        },
        openFullscreen: function () {
            var elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        },

        /* Close fullscreen */
        closeFullscreen: function () {
            if (!document.fullscreenElement) return;
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        },
        removeEvents: function () {
            if (this.canvas) {
                this.canvas.removeEventListener('touchstart', this.draw, false);
                this.canvas.removeEventListener('touchmove', this.draw, false);
                this.canvas.removeEventListener('touchend', this.draw, false);
                this.canvas.removeEventListener('mousedown', this.draw, false);
                this.canvas.removeEventListener('mousemove', this.draw, false);
                this.canvas.removeEventListener('mouseup', this.draw, false);
            }
            document.body.removeEventListener('touchmove', this.preventEvent)
            document.body.removeEventListener('drop', this.preventEvent)
            document.body.removeEventListener('dragenter', this.preventEvent)
            window.removeEventListener('touchmove', this.preventEvent)
            window.removeEventListener('drop', this.preventEvent)
            window.removeEventListener('dragenter', this.preventEvent)
        },
        openModal: function () {
            this.emptyCanvas = true;
            this.openFullscreen();
            if (window.screen.orientation) {

                window.screen.orientation.lock('portrait-primary')
            }
            //  screen.lockOrientation("portrait-primary");
            if (window.screen)
                this.removeEvents();
            this.makeBodyNoscalable();
            this.scanning = true;
            this.canvas = document.getElementById('signatureCanvas');
            this.context = this.canvas.getContext('2d');

            // create a this.drawer which tracks touch movements

            // create a function to pass touch events and coordinates to this.drawer


            // detect touch capabilities
            var touchAvailable = ('createTouch' in document) || ('ontouchstart' in window);

            // attach the touchstart, touchmove, touchend event listeners.
            if (touchAvailable) {
                console.log('tousch')
                this.canvas.addEventListener('touchstart', this.draw, false);
                this.canvas.addEventListener('touchmove', this.draw, false);
                this.canvas.addEventListener('touchend', this.draw, false);
            }
            // attach the mousedown, mousemove, mouseup event listeners.
            else {
                console.log('mouse')
                this.canvas.addEventListener('mousedown', this.draw, false);
                this.canvas.addEventListener('mousemove', this.draw, false);
                this.canvas.addEventListener('mouseup', this.draw, false);
            }

            // prevent elastic scrolling
            document.body.addEventListener('touchmove', this.preventEvent)
            document.body.addEventListener('drop', this.preventEvent)
            document.body.addEventListener('dragenter', this.preventEvent)
            window.addEventListener('touchmove', this.preventEvent)
            window.addEventListener('drop', this.preventEvent)
            window.addEventListener('dragenter', this.preventEvent)

        },
        closeModal: function () {
            this.closeFullscreen();
            this.clearCanvas();
            this.removeEvents();
            this.scanning = false;
            this.makeBodyNormal();
        },
        draw: function (event) {
            var type = null;
            // map mouse events to touch events
            switch (event.type) {
                case "mousedown":
                    event.touches = [];
                    event.touches[0] = {
                        pageX: event.pageX,
                        pageY: event.pageY
                    };
                    type = "touchstart";
                    break;
                case "mousemove":
                    event.touches = [];
                    event.touches[0] = {
                        pageX: event.pageX,
                        pageY: event.pageY
                    };
                    type = "touchmove";
                    break;
                case "mouseup":
                    event.touches = [];
                    event.touches[0] = {
                        pageX: event.pageX,
                        pageY: event.pageY
                    };
                    type = "touchend";
                    break;
            }

            // touchend clear the touches[0], so we need to use changedTouches[0]
            var coors;
            if (event.type === "touchend") {
                coors = {
                    x: event.changedTouches[0].pageX,
                    y: event.changedTouches[0].pageY
                };
            } else {
                // get the touch coordinates
                coors = {
                    x: event.touches[0].pageX,
                    y: event.touches[0].pageY
                };
            }
            type = type || event.type
            // pass the coordinates to the appropriate handler
            this[type](coors);
        },
        save: function () {
            var myImage = new Image();
            myImage.src = this.canvas.toDataURL();
            let vm = this;
            myImage.onload = function () {
                if (vm.canvasHeight > vm.canvasWidth) {
                    // reset the canvas with new dimensions
                    vm.canvas.width = vm.canvasHeight;
                    vm.canvas.height = vm.canvasWidth;
                    vm.canvasWidth = vm.canvas.width;
                    vm.canvasHeight = vm.canvas.height;

                    vm.context.save();
                    // translate and rotate
                    vm.context.translate(vm.canvasWidth, vm.canvasWidth / vm.canvasWidth);
                    vm.context.rotate(Math.PI / 2);

                    // draw the previows image, now rotated
                    vm.context.drawImage(myImage, 0, 0);
                    vm.context.restore();

                }

                // clear the temporary image
                myImage = null;

                var data = vm.canvas.toDataURL('image/png', 0.8);
                vm.$emit('input', data);
                vm.$emit('change', data);
                vm.closeModal();
            }

        }
    },
    mounted() {
        let vm = this;
        window.onresize = function () {
            vm.canvasWidth = window.screen.width - 20;
            vm.canvasHeight = window.screen.height - 20;
        }


    },
    beforeDestroy: function () {
        this.makeBodyNormal();
        this.closeModal();
    },

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
::before,
::after {
    pointer-events: none;
}

.signature_fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: white;
}

.square {
    position: fixed;
    bottom: calc(50% - 150px);
    right: calc(50% - 150px);
    border: 6px solid red;
    height: 300px;
    width: 300px;
}

#signatureCanvas {
    border: 10px solid blue;
    z-index: 1000;
}

.scanbuttons {
    position: fixed;
    z-index: 120;
    bottom: 0;
}

.drawing {
    overflow: hidden;
    height: 100%;

}

.makeSignatureInfo {
    position: fixed;
    right: -80px;
    bottom: 50%;
    z-index: 2000;
    font-size: 42px;
    transform: rotate(-90deg);
    color: blue;
}

@media (orientation: landscape) {
    .makeSignatureInfo {
        position: fixed;
        right: 0;
        left: 0;
        width: 100%;
        text-align: center;
        bottom: 120px;
        z-index: 2000;
        font-size: 42px;
        transform: rotate(0deg);
        color: blue;
    }
}

.drawing body {
    position: fixed;
    overflow: hidden;
    height: 100%;
}
</style>