export default {
    store: {},
    valid: true,
    errors: [],
    warnings: [],
    validateQuantity: function (state, getters, rootGetters) {
        let products = state.order.products;
        let valid = true;
        products.forEach(item => {
            if (item.quantity != getters.countCylinder(item.product_id)) {
                valid = false;
                this.pushProductError(item, 'quantity');
            }

            if (item.allowEmpty != true && !getters.isAllCylindersFull(item.product_id)) {
                this.pushProductError(item, 'emptyCylinder');
            }

            let productOnCar = rootGetters['storage/getProduct'](item.product_id);
            if (productOnCar) {
                if (item.quantityFull > 0 && productOnCar.quantityFull < item.quantityFull) this.pushProductError(item, 'noEnoughProductCar');
                if (item.quantityEmpty > 0 && productOnCar.quantityEmpty < item.quantityEmpty) this.pushProductError(item, 'noEnoughProductCar');
            } else {
                this.pushProductError(item, 'noEnoughProductCar');
            }



        })
        let badCylinder = getters.getBadCylinder;
        console.log('badCylinder', badCylinder)
        badCylinder.forEach(cylinder => {
            this.pushCylinderError(cylinder, 'badCylinderProduct', 'danger');
        })

        return valid;
    },

    validateCylinder: function (state, getters, rootState) {
        state.cylinders.forEach(cylinder => {
            let product = getters.getProduct(cylinder.product_id);
            console.log('s', product);
            if (product) {
                let allowEmpty = product.allowEmpty == true ? true : false;
                if (cylinder.cylinder_status == 'empty') {
                    this.pushCylinderError(cylinder, 'empty', (allowEmpty == true ? 'warning' : 'error'));
                }
                if (cylinder.cylinder_complaint == '1') {
                    this.pushCylinderError(cylinder, 'complaint', (allowEmpty == true ? 'warning' : 'error'));
                }
            } else {
                this.pushCylinderError(cylinder, 'orderFail');
            }
            if (cylinder.location_id != rootState.location_id) {
                this.pushCylinderError(cylinder, 'locationFail');
            }

        });


    },
    pushProductError: function (product, type, level = 'error') {
        let obj = { cylinder_id: 0, product_id: product.product_id, type: type, info: '' };
        switch (type) {
            case 'quantity':
                obj.info = 'Stan ' + product.title + ' jest niezgodny';
                break;
            case 'emptyCylinder':
                obj.info = 'Produkt ' + product.title + ' zawiera puste butle';
                break;
            case 'noEnoughProductCar':
                obj.info = 'Produkt ' + product.title + ' nie ma tyle na aucie';
                break;
        }
        if (level == 'warning') {
            this.warnings.push(obj)
        }
        else {
            this.errors.push(obj)
        }
    },
    pushCylinderError: function (cylinder, type, level = 'error') {
        let obj = { cylinder_id: cylinder.cylinder_id, product_id: cylinder.product_id, type: type, info: '' };
        switch (type) {
            case 'empty':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' jest pusta';
                break;
            case 'complaint':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' ma reklamacje';
                break;
            case 'orderFail':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie pasuje do zamówień';
                break;
            case 'locationFail':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie znajduje się na aucie';
                break;
            case 'badCylinderProduct':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie pasuje do żadnego produktu';
                break;
        }
        if (level == 'warning') {
            this.warnings.push(obj)
        }
        else {
            this.errors.push(obj)
        }
    }
}