<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/certification/client"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Dodaj klienta</h2>
      <button
        @click="save"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        Zapisz
      </button>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="mb-3">
        <label class="mb-2">Typ:</label>
        <div>
          <button
            @click="item.typ = '1'"
            :class="item.typ == '1' ? 'btn-primary' : 'btn-outline-primary'"
            class="btn me-2"
          >
            Firma
          </button>
          <button
            @click="item.typ = '0'"
            :class="item.typ == '0' ? 'btn-primary' : 'btn-outline-primary'"
            class="btn"
          >
            Osoba fizyczna
          </button>
        </div>
      </div>

      <div class="w-100">
        <div
          class="form-group mb-3"
          v-if="item.typ == '1'"
        >
          <label class="form-label">NIP</label>
          <div class="input-group">
            <input
              v-model="item.nip"
              type="text"
              class="form-control"
            />
            <button
              @click="loadFromGus"
              class="btn btn-outline-secondary"
            >
              Wczytaj z GUS
            </button>
          </div>
        </div>
        <div
          class="card-body p-3"
          v-if="item.nazwa && item.typ == '1' && !showCompanyForm"
        >
          {{ item.nazwa }}<br />
          {{ item.ulica }} {{ item.nrdomu }}{{ item.nrlokalu ? "/" + item.nrlokalu : "" }}<br />
          {{ item.kodpocztowy }} {{ item.miasto }}<br />
          <span v-if="item.nip"> NIP: {{ item.nip }} </span><br>
          <a href="#" @click.prevent="showCompanyForm=!showCompanyForm">Popraw</a>
        </div>
        <div v-if="item.typ == '1' && showCompanyForm">
          <div class="form-group mb-3">
            <label class="form-label">Nazwa firmy</label>
            <input
              v-model="item.nazwa"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Ulica</label>
            <input
              v-model="item.ulica"
              type="text"
              class="form-control"
            />
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group mb-3">
                <label class="form-label">Nr domu</label>
                <input
                  v-model="item.nrdomu"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mb-3">
                <label class="form-label">Nr lokalu</label>
                <input
                  v-model="item.nrlokalu"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Kod pocztowy</label>
            <input
              v-model="item.kodpocztowy"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Miasto</label>
            <input
              v-model="item.miasto"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div v-if="item.typ == '0'">
          <div class="form-group mb-3">
            <label class="form-label">Imię</label>
            <input
              v-model="item.imie"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Nazwisko</label>
            <input
              v-model="item.nazwisko"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Numer telefonu</label>
          <input
            v-model="item.telefon"
            type="tel"
            class="form-control"
          />
        </div>
        <div class="form-group mb-3">
          <label class="form-label">Email</label>
          <input
            v-model="item.email"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";
import axios from "axios";

export default {
  name: "SimpleaddIndex",
  components: {},
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      date: "",
      item: {
        typ: "1",
        nip: "",
        nazwa: "",
        ulica: "",
        nrdomu: "",
        nrlokalu: "",
        kodpocztowy: "",
        miasto: "",
        regon: "",
        email: "",
        telefon: "",
        kraj: "",
      },
      showCompanyForm: false,
      loading: false,
      showAll: false,
      validation:{},
      query: "",
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
  },
  methods: {
    load: function () {
      // myApi.getAllCustomer({ query: this.query }).then((response) => {
      //   this.last = response.data.data;
      // });
    },
    choose: function (item) {
      this.$store.commit("certification/setClient", item);
      this.$router.push("/certification/add");
    },
    save: function () {
      myApi.postCustomer(this.item).then(
        (response) => {
          this.$swal("Dodano", "", "success");

          this.item = response.data.data;
          this.$store.commit("certification/setClient", this.item);
          this.$router.push("/certification/add");
        },
        (e) => {
          this.$swal("Wystąpił błąd", JSON.stringify(e.response.data.messages), "error");
        }
      );
    },
    loadFromGus: function () {
      if (!this.item.nip.replace(/\D+/g, "")) {
        return;
      }
      // gusLoading.value = true;
      let config = {
        headers: {
          "x-api-key": "1234",
        },
      };
      axios
        .get("https://serwer.magico.pl/api/v1/gus/" + this.item.nip.replace(/\D+/g, ""), config)
        .then(
          (response) => {
            this.item.nazwa = response.data.data.name;
            this.item.ulica = response.data.data.street;
            this.item.nrdomu = response.data.data.propertyNumber;
            this.item.nrlokalu = response.data.data.apartmentNumber;
            this.item.kodpocztowy = response.data.data.zipCode;
            this.item.miasto = response.data.data.city;
            this.item.regon = response.data.data.regon;
            this.item.email = response.data.data.email;
            this.item.telefon = response.data.data.phone;
            this.item.kraj = "PL";
            this.showCompanyForm = false;
            // gusLoading.value = false;
            // props.validation.nip = false;
            // vm.isLoadingGus = false;
            // console.log(response.dat);
          },
          () => {
            this.$swal("Nie znaleziono", "Sprawdź NIP lub uzupełnij dane firmy ręcznie", "error");
            this.showCompanyForm = true;
            this.item = {
              typ: "1",
              nip: this.item.nip,
              nazwa: "",
              ulica: "",
              nrdomu: "",
              nrlokalu: "",
              kodpocztowy: "",
              miasto: "",
              regon: "",
              email: "",
              telefon: "",
              kraj: "",
            };
            // gusLoading.value = false;
            // props.validation.nip = "Nie znaleziono firmy o podanym numerze NIP. ";
          }
        );
    },
  },
  created() {},
  mounted() {
    this.load();
  },
};
</script>
