<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">
        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Zamówienia</h2>
            <button @click="reload" class="btn btn-lg btn-secondary m-2"><i class="bi bi-arrow-clockwise"></i></button>

        </div>
        <orders></orders>
    </div>
</template>
<script>
import Orders from '../components/Orders.vue'

export default {
    name: 'OrdersView',
    props: {
        listen: Boolean,
    },
    components: {
        Orders,
    },
    data: function () {
        return {
            code: '',
            data: null,
            location_id: 0,
        }
    },
    computed: {

    },
    methods: {
        // load: function () {
        //     this.$http.get('greenapp/cylinder?nazwa=&limit=1000&page=1&location_id=' + this.location_id).then(response => {
        //         this.data = response.data.data;
        //     }, () => {

        //     })
        // },
        reload: function () {
            this.$store.dispatch('storage/syncState').then(() => {
                this.$toast('Odświeżono');
            }, () => {
                this.$toast.error('Brak połaczenia z serwerem');
            });
        },
    },
    mounted() {
        this.$store.dispatch('storage/syncState')
        this.location_id = this.$store.state.location_id;
        // this.load(this.$route.params.qrcode);
    },
    beforeDestroy: function () { }
}
</script>
<style>
a {
    text-decoration: none;
}
</style>