import myApi from "@/api/myApi";

export default {
  namespaced: true,
  state: {
    client: null,
    cylinders: [],
    client_phone: "",
    phone: "",
  },
  mutations: {
    setClient(state, data) {
      state.client = data;
    },
    setClientPhone(state, data) {
      state.client_phone = data;
    },
    addCylinder(state, data) {
      state.cylinders.push(data);
    },
    updateCylinder(state, data) {
      state.cylinders[data.key] = data.value;
    },
    setPhone(state, data) {
      state.phone = data;
    },
    reset(state) {
      state.client = null;
      state.client_phone = null;
      state.cylinders = [];
      state.phone = null;
    },
  },
  getters: {},
  actions: {
    createCertification({ state, rootState }) {
      return new Promise((resolve, reject) => {
        myApi
          .postCertification({
            customer_id: state.client.id,
            user_id: rootState.user_id,
            phone: state.client_phone,
            location_id: rootState.location_id,
            notification_phone: state.phone,
            items: state.cylinders,
          })
          .then(
            (r) => resolve(r),
            (error) => reject(error)
          );
      });
    },

    reset: function ({ commit }) {
      commit("reset");
    },
  },
};
