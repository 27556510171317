<template>
    <div>

</div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
export default {
    name: "UpdateNotifier",
    methods: {
        lastCompliationDate: function () {
            axios.get('/?=' + dayjs().unix()).then(response => {
                let parser = new DOMParser();
                let xmlDoc = parser.parseFromString(response.data, "text/html");
                let date = xmlDoc.getElementsByTagName('html')[0].getAttribute('data-build-timestamp-utc');
                if (localStorage.getItem('version-date') != document.documentElement.dataset.buildTimestampUtc) {
                    this.$swal({ 'icon': 'success', 'title': 'Zaktualizowano pomyślnie' });
                }
                localStorage.setItem('version-date', document.documentElement.dataset.buildTimestampUtc);
                if (date != document.documentElement.dataset.buildTimestampUtc) {
                    caches.keys().then((cacheNames) =>
                        Promise.all(
                            cacheNames.map((cacheName) => {
                                console.log('Delete cache ' + cacheName)
                                return caches.delete(cacheName);
                            })
                        )
                    );
                    this.$swal({
                        'icon': 'info',
                        title: 'Istnieje nowsza wersja aplikacji',
                        text: 'Zostanie właśnie pobrana na twoje urządzenie'
                    }).then(function () {
                        window.location.href = window.location.href + 'updated';
                    })
                }
            })
        },
    },
    mounted() {
        if (this.$route.name == 'updated') {
            window.location.href = window.location.href.replace('updated', '');
        }else{
            this.lastCompliationDate();
        }
    },
    computed: {
        buildDate: function () {
            return dayjs(document.documentElement.dataset.buildTimestampUtc).format('YYYY-MM-DD HH:mm:ss')
        }
    }
}
</script>