<template>
    <div v-if="complaint"
        class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between" style="background: #dadada">
            <button @click="cancel" href="wydawanie-3-detail.html" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></button>
            <a @click.prevent="create" href="#" class="btn btn-lg btn-success flex-fill m-2">Zgłoś reklamację</a>
            <!-- <a href="#" class="btn btn-lg btn-danger flex-fill m-2">Butle z błędami (3)</a> -->

        </div>

        <div v-if="complaint.order" class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
            <span class="col-9 me-2">Reklamacja butli od klienta:<br> {{ complaint.order.title }}</span>
        </div>


        <div class="w-100 p-2 pb-5 flex-grow-1" style="overflow: auto;  ">
            <form>
                <h3>Butla {{ complaint.cylinder ? complaint.cylinder.cylinder_qr_code : 'nieoznakowana' }}</h3>
                <p>{{ complaint.product.name }}</p>
                <span class="text-danger mb-3 d-block" v-if="!complaint.cylinder">Pamiętaj. Musisz napisać na butli numer
                    reklamacji.</span>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Przyczyna reklamacji</label>

                    <div v-for="it, k in errors" :key="k" class="form-check">
                        <MagicoCheckbox v-model="checkboxes" :value="it">
                            {{ it }}
                        </MagicoCheckbox>
                    </div>



                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Opis dodatkowy</label>
                    <textarea v-model="description" class="form-control" id="exampleFormControlTextarea1"
                        rows="3"></textarea>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import MagicoCheckbox from '@/components/MagicoCheckbox.vue';

export default {
    name: "ComplaintCreate",
    computed: {
        complaint: function () {
            return this.$store.state.complaint.createComplaint;
        }
    },
    data: function () {
        return {
            checkboxes: [],
            description: '',
            errors: [
                'Jakość gazu',
                'Wyciek na gwincie',
                'Wyciek na zaworze',
                'Brak uszczelnienia zaworu',
                'Uszkodzenie mechaniczne butli',
                ' Uszkodzenie mechaniczne zaworu',
                'Inny',
            ]
        };
    },
    methods: {
        create: function () {
            this.$store.dispatch('complaint/approveComplaint', { checkboxes: this.checkboxes, description: this.description }).then((number) => {
                this.$router.push('/complaint/' + number);
            })
        },
        cancel: function () {
            this.$swal({
                title: 'Chcesz anulować reklamacje?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Tak, anuluję!'
            }).then(response => {
                if (response.isConfirmed) {
                    this.$router.push('/pickup');
                }
            })
        },
    },
    components: { MagicoCheckbox }
}

</script>