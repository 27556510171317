<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <div class="flex-fill text-center">
                <h3>Wybierz auto źrodłowe</h3>
            </div>
        </div>
        <div class="w-100 p-3 pb-5 flex-grow-1" style="overflow: auto;">
            <div v-for="item, key in items" :key="key">
                <a @click="setLocation(item)" v-if="item.distance < 2 || showAll"
                    class="bg-light p-2 text-dark d-flex justify-content-between mb-2">
                    <span class="col-10 pe-2 ">{{ item.location_name }}
                        <span :title="item.distance + 'km'" class="badge bg-success" v-if="item.distance < 2">W
                            magazynie</span>
                    </span>
                    <span class="col-2 text-end"><button class="btn  "><i class="bi bi-caret-right"></i></button></span>
                </a>
            </div>
            <div class="text-center">
                <a href="#" @click.prevent="showAll = !showAll">{{
                    showAll?'Ukryj auta poza magazynem': 'Pokaż wszystkie auta'
                }}</a>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api/myApi';
export default {
    data: function () {
        return {
            items: [],
            showAll: false,
        }
    },
    created: function () {
        console.log(this.$store.state.location_id);
        api.findStorages('auto', this.$store.state.location_id).then(response => {
            this.items = response.data;
            this.items.sort((a, b) => a.distance > b.distance ? 1 : -1);
        });
    },
    methods: {
        setLocation: function (location) {
            this.$store.commit('pickup/setLocationFrom', location);
            this.$store.dispatch('pickup/loadFromState');
            this.$router.push('/pickup');
        }
    }
}
</script>