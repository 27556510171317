<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Wybierz zamówienie</h2>
        </div>
        <div class="w-100 p-2  flex-grow-1 d-flex flex-column align-items-center pt-4 text-center"
            style="overflow: auto; ">
            <div v-if="orders.length == 0">Brak zamówień</div>
            <router-link v-for="order, key in orders " :key="key" :to="'/disloading/start/' + order.id"
                class="text-dark fs-5 mb-4">{{ order.title }}</router-link>


        </div>

    </div>


</template>
<script>


export default {
    name: 'DisloadingSelectOrder',
    components: {},
    data: function () {
        return {
            selectedOrder: {},
            id: 0,
        }
    },
    props: {
    },
    computed: {
        orders: function () {
            return this.$store.getters['storage/getOpenOrders'];
        }
    },
    methods: {

    },
    created() {

    },
    mounted() {


    }
}
</script>