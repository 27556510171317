<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <div class="flex-fill text-center">
                <h3>Wybierz magazyn źrodłowy</h3>
            </div>
        </div>
        <div class="w-100 p-3 pb-5 flex-grow-1" style="overflow: auto;">
            <a @click="setLocation(item)" v-for="item, key in items" :key="key"
                class="bg-light p-2 text-dark d-flex justify-content-between mb-2">
                <span class="col-9 pe-2">{{ item.location_name }}</span>
                <span class="col-3"><button class="btn btn-primary w-100">Wybierz</button></span>
            </a>

        </div>
    </div>
</template>
<script>
import api from '@/api/myApi';
export default {
    data: function () {
        return {
            items: [],
        }
    },
    created: function () {
        api.findStorages('magazyn').then(response => {
            this.items = response.data;
        });
    },
    methods: {
        setLocation: function (location) {
            this.$store.commit('zaladunek/setLocationFrom', location);
            this.$router.push('/loading');
        }
    }
}
</script>