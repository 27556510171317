<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/legalization" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Edytuj legalizacje</h2>
            <button @click="confirm" type="button" class="btn btn-lg btn-success flex-fill m-2">Zapisz</button>
        </div>


        <div class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start container"
            style="
                                                                                    overflow: auto; ">
            <div class="w-100">
                <LegalizationForm v-model="item" :validation="validation"></LegalizationForm>
            </div>
        </div>
    </div>
</template>
<script>
import LegalizationForm from '@/components/legalization/LegalizationForm.vue';
import api from '@/api/myApi';

export default {
    name: 'QuestionStart',
    components: { LegalizationForm },
    computed: {

    },
    data: function () {
        return {
            validation: {},
            item: {}
        }
    },
    mounted: function () {
        this.load();
    },
    methods: {

        confirm: function () {

            api.putLegalization(this.$route.params.id, this.item).then(() => {
                this.$router.push('/legalization/' + this.$route.params.id + '/cylinder');
                this.$toast('Zapisano poprawnie')
            }, (err) => {
                this.validation = err.response.data.messages;
            })

        },
        load: function () {
            api.getLegalization(this.$route.params.id).then(response => {
                this.item = response.data;
            })
        }
    }
}
</script>