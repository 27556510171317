<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center p-4">
    <div v-if="!$store.state.kartaDrogowa">
      <h1>Brak otwartej karty drogowej</h1>
      <div class="text-center">
        <button @click="clickOdswiez" class="btn btn-outline-primary me-2">
          <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
          Sprawdz ponownie
        </button>
        <button @click="logout" class="btn btn-outline-secondary">
          <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
          Wyloguj
        </button>
      </div>
    </div>
    <div class="text-center" v-else>
      <h2 class="mb-5">Witaj! Rozpocznijmy pracę!</h2>
      <div class="py-2">

        <svg id="Capa_1" enable-background="new 0 0 512 512" height="160" viewBox="0 0 512 512" width="160"
          xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="m297.42 18.898h-.279c-5.939 0-11.801-1.478-16.963-4.414-40.424-22.991-100.11-20.316-127.191 20.387-11.228 16.876-12.344 42.633.624 57.74 2.301 2.68 3.494 6.138 3.572 9.669l1.28 57.58h194.958l1.819-81.844c.721-32.435-25.376-59.118-57.82-59.118z"
              fill="#8c818f" />
            <path
              d="m297.42 18.898h-.279c-5.939 0-11.8-1.478-16.963-4.414-25.195-14.33-57.87-18.684-85.473-10.08 21.258-1.139 43.337 3.852 61.521 13.921 5.162 2.858 11.024 4.297 16.963 4.297h.279c32.444 0 58.54 25.978 57.819 57.557l-1.819 79.681h23.952l1.819-81.844c.722-32.435-25.375-59.118-57.819-59.118z"
              fill="#766d78" />
            <path
              d="m307.224 186.56c-3.098-20.024-20.334-34.799-40.596-34.799h-21.255c-20.263 0-37.499 14.775-40.596 34.799-2.972 19.211-14.45 35.969-31.104 45.996-24.015 14.458-40.08 40.777-40.08 70.852v191.656h244.816v-191.656c0-30.075-16.064-56.393-40.08-70.852-16.655-10.027-28.134-26.785-31.105-45.996z"
              fill="#c5bec6" />
            <path
              d="m338.328 232.556c-16.654-10.026-28.133-26.785-31.104-45.996-3.098-20.024-20.334-34.799-40.596-34.799h-21.255c-2.275 0-4.508.196-6.691.555 17.26 2.839 31.155 16.468 33.905 34.244 2.972 19.211 14.45 35.969 31.104 45.996 24.015 14.458 40.08 40.777 40.08 70.852v191.656h34.637v-191.656c0-30.075-16.064-56.394-40.08-70.852z"
              fill="#a59da7" />
            <path
              d="m411.605 416.509-47.173-116.127c-7.956-19.586-26.988-32.397-48.128-32.397h-120.609c-21.14 0-40.172 12.811-48.128 32.397l-47.173 116.127 34.84 14.153 37.201-48.551v103.286h167.128v-103.286l37.201 48.551z"
              fill="#80b6fc" />
            <g fill="#62a4fb">
              <path
                d="m364.432 300.382c-7.956-19.586-26.988-32.397-48.128-32.397h-25.486c21.14 0 40.172 12.811 48.128 32.396l47.173 116.127-15.404 6.257 6.05 7.896 34.84-14.153z" />
              <path
                d="m317.806 402.083 67.692 33.756c9.294 4.635 20.586.858 25.221-8.437 4.635-9.294.858-20.586-8.437-25.221l-67.692-33.756z" />
              <path
                d="m194.194 402.083-67.692 33.756c-9.294 4.635-20.586.858-25.221-8.437-4.635-9.294-.858-20.586 8.437-25.221l67.692-33.756z" />
            </g>
            <path
              d="m256 332.883c-49.738 0-90.062 40.324-90.062 90.062 0 18.673 5.681 36.033 15.412 50.416h43.236c-16.802-10.508-27.999-29.181-27.999-50.416 0-32.761 26.653-59.414 59.414-59.414s59.414 26.653 59.414 59.414c0 21.235-11.198 39.908-27.999 50.416h43.236c9.731-14.383 15.412-31.743 15.412-50.416-.002-49.738-40.326-90.062-90.064-90.062z"
              fill="#c77e67" />
            <path
              d="m224.433 169.157v94.823c0 6.599 2.89 12.867 7.907 17.153l5.936 5.07c10.207 8.719 25.24 8.719 35.447 0l5.936-5.07c5.018-4.286 7.907-10.554 7.907-17.153v-94.823z"
              fill="#ffcdbe" />
            <path d="m287.567 251.231v-82.074h-63.133v82.075c19.732 6.636 43.401 6.636 63.133-.001z" fill="#ebae9d" />
            <path
              d="m345.329 181.511h-178.773c-11.004 0-19.924-8.92-19.924-19.924s8.92-19.924 19.924-19.924h178.773c11.004 0 19.924 8.92 19.924 19.924 0 11.003-8.92 19.924-19.924 19.924z"
              fill="#ebae9d" />
            <path
              d="m322.361 199.148c-21.022 44.791-111.815 44.791-132.837 0-7.835-16.707-7.539-55.384-.802-87.676 1.428-6.847 7.015-12.089 13.967-12.861 31.416-3.488 61.876-15.622 87.042-34.759 3.623-2.755 8.643-2.727 12.177.142.199.162.396.325.59.49 23.612 20.061 32.77 107.157 19.863 134.664z"
              fill="#ffcdbe" />
            <g fill="#766d78">
              <path
                d="m225.825 158.561c-4.143 0-7.5-3.357-7.5-7.5v-2.088c0-4.143 3.357-7.5 7.5-7.5s7.5 3.357 7.5 7.5v2.088c0 4.142-3.357 7.5-7.5 7.5z" />
              <path
                d="m286.06 158.561c-4.143 0-7.5-3.357-7.5-7.5v-2.088c0-4.143 3.357-7.5 7.5-7.5s7.5 3.357 7.5 7.5v2.088c0 4.142-3.358 7.5-7.5 7.5z" />
            </g>
            <path
              d="m255.942 199.204c-5.998 0-11.996-1.295-17.001-3.885-3.679-1.904-5.117-6.43-3.214-10.108 1.904-3.679 6.429-5.118 10.108-3.214 5.762 2.982 14.451 2.982 20.213 0 3.679-1.904 8.203-.465 10.108 3.214 1.903 3.679.465 8.204-3.214 10.108-5.004 2.59-11.002 3.885-17 3.885z"
              fill="#766d78" />
            <path
              d="m368.531 462.204c-8.298 0-16.434-2.503-23.185-7.328-18.156-12.978-40.084-20.154-62.843-20.154h-53.005c-22.759 0-44.687 7.176-62.843 20.154-6.751 4.826-14.887 7.328-23.185 7.328h-66.259c-19.535 0-35.372 15.837-35.372 35.372v8.952c0 3.023 2.45 5.473 5.473 5.473h417.375c3.023 0 5.473-2.45 5.473-5.473v-8.952c0-19.535-15.837-35.372-35.372-35.372z"
              fill="#8c818f" />
            <path
              d="m464.688 487.403h-417.376c-1.361 0-2.59-.515-3.547-1.337-1.242 3.609-1.926 7.478-1.926 11.509v8.952c0 3.023 2.45 5.473 5.473 5.473h417.375c3.023 0 5.473-2.45 5.473-5.473v-8.952c0-4.031-.684-7.9-1.926-11.509-.956.822-2.186 1.337-3.546 1.337z"
              fill="#766d78" />
            <path
              d="m347.751 382.452-28.321-32.015c-3.417-3.863-9.318-4.224-13.181-.807l-4.83 4.273c-10.132 8.963-11.08 24.441-2.117 34.573l8.239 9.314c8.963 10.132 24.442 11.079 34.573 2.117l4.83-4.273c3.863-3.417 4.224-9.319.807-13.182z"
              fill="#ffcdbe" />
            <path
              d="m164.249 382.452 28.321-32.015c3.417-3.863 9.318-4.224 13.181-.807l4.83 4.273c10.132 8.963 11.08 24.441 2.117 34.573l-8.239 9.314c-8.963 10.132-24.442 11.079-34.573 2.117l-4.83-4.273c-3.863-3.417-4.224-9.319-.807-13.182z"
              fill="#ffcdbe" />
          </g>
        </svg>
      </div>

      Karta drogowa nr
      <b>{{
        $store.state.kartaDrogowa
          ? $store.state.kartaDrogowa.numer
          : ""
      }}</b>
      <p>
        Twój aktualny pojazd:<b> {{ $store.state.kartaDrogowa.location_name }}</b>
      </p>
      <button @click="start" class="btn btn-outline-secondary p-4">Rozpocznij</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "StartKartaView",
  components: {},
  data: function () {
    return {
      isLoading: false,
    };
  },
  props: {},
  computed: {},
  methods: {
    clickOdswiez: function () {
      this.isLoading = true;
      this.$store.dispatch("getKartaDrogowa");
      this.isLoading = false;
    },
    logout: function () {
      this.$store.commit('logout');
      this.$router.push('/');
    },
    start: function () {
      this.$store.dispatch('storage/syncState');
      this.$router.push('/');
    }
  },
  mounted() {
    this.$store.dispatch("getKartaDrogowa");
  },
};
</script>

<style>

</style>
