<template>
    <div>
        <div class="rounded-3 p-2 border mt-2" v-for="item, key in data" :key="key">

            <h5>{{ item.complaint_number }}</h5>
            {{ item.created_at }}
            <div class="">
                <ul class="ps-3">
                    <li v-for="it, k in JSON.parse(item.checkboxes)" :key="k">{{ it }}</li>
                </ul>
                <small>{{ item.description }}</small>
            </div>
        </div>
    </div>
</template>
<script>
import myApi from '@/api/myApi';

export default {
    name: 'CylinderLogs',
    props: ['cylinder_id', 'limit'],
    data: function () {
        return {
            statusLabel: { 'full': 'Pełna', 'emtpy': 'Pusta' },
            data: [],
        }
    },
    watch: {
        cylinder_id: function () {
            this.getLogs();
        }
    },
    methods: {
        getLogs: function () {
            if (!this.cylinder_id) {
                return
            }
            let vm = this;
            myApi.getCylinderComplaint(this.cylinder_id).then(response => {
                vm.data = response.data.data;
            })
        },
    },
    mounted() {
        this.getLogs();
    }
}
</script>