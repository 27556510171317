<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Stan magazynu</h2>

        </div>

        <div class="w-100 p-2  flex-grow-1" style="overflow: auto;  ">
            <input type="text" class="input-lg form-control mb-1" v-model="filter" placeholder="Wpisz aby wyszukać">
            <div >
                <span 
                    class="  px-2 text-dark d-flex justify-content-between align-items-center mb-2 ">
                    <span class="flex-fill"></span>
                    <div class="d-flex col-3 me-2">
                        <span style="font-size: 8px;" class=" w-100 badge bg-secondary  d-flex align-items-center justify-content-center ">OZNAKOWANE <br>PUSTE / PEŁNE</span>
                    </div>
                    <div class="d-flex col-3 ">
                        <span style="font-size: 8px;" class=" w-100 badge bg-primary  d-flex align-items-center justify-content-center ">NIEOZNAKOWANE <br>PUSTE / PEŁNE</span>

                    </div>
                </span>
            </div>


            <div v-for="item, key in products" :key="key">
                <router-link v-if="filter == '' || item.name.toLowerCase().includes(filter.toLowerCase())"
                    :to="'/storage/product/' + item.product_id"
                    class="bg-light p-2 text-dark d-flex justify-content-between align-items-center mb-2 ">
                    <span class="flex-fill">{{ item.name }}</span>
                    <div class="d-flex col-3 me-2">
                        <span class=" w-100 badge bg-secondary fs-6 d-flex align-items-center justify-content-center ">{{
                            parseInt(item.cylinderEmpty)}} / {{parseInt(item.cylinderFull) 
                        }}</span>
                    </div>
                    <div class="d-flex col-3">
                        <span class=" w-100 badge bg-primary fs-6 d-flex align-items-center justify-content-center ">{{
                            parseInt(item.quantityEmpty)}} / {{parseInt(item.quantityFull) 
                        }}</span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CylinderInfo',
    props: {
        listen: Boolean,
    },
    data: function () {
        return {
            code: '',
            data: null,
            location_id: 0,
            filter: ''
        }
    },
    computed: {
        products: function () {
            return this.$store.getters['storage/getProducts'];
        }
    },

    methods: {

    },
    mounted() {
        this.location_id = this.$store.state.location_id;
        this.$store.dispatch('storage/syncState')
    },
    beforeDestroy: function () { }
}
</script>

<style>
a {
    text-decoration: none;
}
</style>