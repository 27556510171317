<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Legalizacja</h2>
            <router-link to="/legalization/create" type="button" class="btn btn-lg btn-success flex-fill m-2">+
                Nowa</router-link>
        </div>
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-center"
        style="overflow: auto; ">
        <h3>Ostatnie procesy legalizacji:</h3>
            <div class="w-100">
                <div v-for="item, key in items" :key="key"
                    class="border-bottom  col-12 py-2 d-flex justify-content-between align-items-center">
                    <div class="text-start">
                        <h4>{{ item.legalization_number }}</h4>
                        <small>{{ item.created_at?.date.substr(0, 16) }}</small>
                    </div>
                    <div>

                        <div class="badge " :class="item.legalization_status == 'close' ? 'bg-success' : 'bg-info'">{{
                            item.legalization_status == 'close' ? 'Zakończona' : 'w trakcie' }}
                        </div>
                    </div>
                    <div>
                        <router-link :to="'/legalization/' + item.legalization_id + '/cylinder'"
                            class="btn btn-outline-primary">
                            Zobacz
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api/myApi';
export default {
    name: 'QuestionStart',
    computed: {

    },
    data: function () {
        return {
            items: [],
            loading: false,
        }
    },
    mounted: function () {
        this.load();
    },
    methods: {
        confirm: function () {
            this.$store.commit('disloading/setOrder', this.order);
            this.$router.push('/disloading');
        },

        load: function () {
            this.loading = true;
            api.getAllLegalization().then(response => {
                this.loading = false;
                this.items = response.data.data;
            })
        }
    }
}
</script>