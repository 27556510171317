import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import StartKarta from "../views/StartKarta.vue";
import StartPin from "../views/StartPin.vue";

import Login from "../views/Login.vue";
import Loading from "../views/loading/Loading.vue";
import LoadingItem from "../views/loading/LoadingItem.vue";
import LoadingLocation from "../views/loading/LoadingLocation.vue";
import LoadingBadCylinder from "../views/loading/LoadingBadCylinder.vue";
import LoadingSelectProduct from "../views/loading/SelectProduct.vue";

import Settings from "../views/Settings.vue";
import Storage from "../views/storage/Storage.vue";
import StorageProduct from "../views/storage/StorageProduct.vue";
import Orders from "../views/Orders.vue";
import ComplaintCreate from "../views/complaint/ComplaintCreate.vue";
import ComplaintSuccess from "../views/complaint/ComplaintSuccess.vue";
import ComplaintSearch from "../views/complaint/ComplaintSearch.vue";

import CylinderSearchSerial from "../views/cylinder/CylinderSearchSerial.vue";

import CylinderSearch from "../views/cylinder/CylinderSearch.vue";
import CylinderShow from "../views/cylinder/CylinderShow.vue";
import SimpleaddIndex from "../views/simpleadd/SimpleaddIndex.vue";
import SimpleaddSelectProduct from "../views/simpleadd/SelectProduct.vue";
import SimpleaddSuccess from "../views/simpleadd/SimpleaddSuccess.vue";

import CertificationIndex from "../views/certification/CertificationIndex.vue";
import CertificationShow from "../views/certification/CertificationShow.vue";
import CertificationClient from "../views/certification/CertificationClient.vue";
import CertificationCylinder from "../views/certification/CertificationCylinder.vue";
import CertificationAdd from "../views/certification/CertificationAdd.vue";

import Summary from "../views/summary/Summary.vue";
import SummaryNoInternet from "../views/summary/SummaryNoInternet.vue";

import SummaryEnd from "../views/SummaryEnd.vue";
import Disloading from "../views/disloading/Disloading.vue";

import DisloadingQuestionStart from "../views/disloading/QuestionStart.vue";
import DisloadingQuestionEnd from "../views/disloading/QuestionEnd.vue";

import DisloadingProduct from "../views/disloading/DisloadingProduct.vue";
import DisloadingBadCylinder from "../views/disloading/DisloadingBadCylinder.vue";

import SelectOrder from "../views/disloading/SelectOrder.vue";

import Pickup from "../views/pickup/Pickup.vue";
import PickupSelectProduct from "../views/pickup/SelectProduct.vue";
import PickupProduct from "../views/pickup/PickupProduct.vue";
import PickupBadCylinder from "../views/pickup/PickupBadCylinder.vue";
import PickupQuestionStart from "../views/pickup/QuestionStart.vue";

import PickupSelectLocation from "../views/pickup/SelectLocation.vue";
import PickupSummary from "../views/pickup/Summary.vue";

import Production from "../views/production/Production.vue";
import ProductionSelectProduct from "../views/production/SelectProduct.vue";
import ProductionProduct from "../views/production/ProductionProduct.vue";

import LegalizationIndex from "../views/legalization/LegalizationIndex.vue";
import LegalizationEdit from "../views/legalization/LegalizationEdit.vue";
import LegalizationClose from "../views/legalization/LegalizationClose.vue";
import LegalizationCreate from "../views/legalization/LegalizationCreate.vue";

import LegalizationCylinder from "../views/legalization/LegalizationCylinder.vue";
import LegalizationAddCylinder from "../views/legalization/LegalizationAddCylinder.vue";
import LegalizationCylinderItem from "../views/legalization/LegalizationCylinderItem.vue";
import LegalizationAddAlienCylinder from "../views/legalization/LegalizationAddAlienCylinder.vue";
import LegalizationMenu from "../views/legalization/LegalizationMenu.vue";
import Menu from "../views/Menu.vue";
import CertificationClientAdd from "@/views/certification/CertificationClientAdd.vue";
import ComplaintDone from "@/views/complaint/ComplaintDone.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/menu",
    name: "",
    component: Menu,
  },
  {
    path: "/login",
    name: "",
    component: Login,
  },
  {
    path: "/start/karta",
    name: "",
    component: StartKarta,
  },
  {
    path: "/start/pin",
    name: "",
    component: StartPin,
  },

  {
    path: "/certification",
    name: "certification",
    component: CertificationIndex,
  },
  {
    path: "/certification/show/:id",
    name: "certificationshow",
    component: CertificationShow,
  },
  {
    path: "/certification/client",
    name: "certificationclient",
    component: CertificationClient,
  },
  {
    path: "/certification/client/add",
    name: "certificationclientadd",
    component: CertificationClientAdd,
  },
  {
    path: "/certification/cylinder",
    name: "certificationcylinder",
    component: CertificationCylinder,
  },
  {
    path: "/certification/cylinder/:id",
    name: "certificationcylinder2",
    component: CertificationCylinder,
  },
  {
    path: "/certification/add",
    name: "certification",
    component: CertificationAdd,
  },
  {
    path: "/",
    name: "",
    component: Home,
  },
  {
    path: "/updated",
    name: "updated",
    component: Home,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/loading",
    name: "loading",
    component: Loading,
  },
  {
    path: "/loading/location",
    name: "loadinglocation",
    component: LoadingLocation,
  },
  {
    path: "/loading/badcylinder",
    name: "loadingbadcylinder",
    component: LoadingBadCylinder,
  },
  {
    path: "/loading/selectproduct",
    name: "loadingselectproduct",
    component: LoadingSelectProduct,
  },
  {
    path: "/loading/item/:id",
    name: "loadingitem",
    component: LoadingItem,
  },
  {
    path: "/summary",
    name: "disloading",
    component: Summary,
  },
  {
    path: "/summary/nointernet",
    name: "SummaryNoInternet",
    component: SummaryNoInternet,
  },
  {
    path: "/summary/end",
    name: "disloading",
    component: SummaryEnd,
  },
  {
    path: "/disloading/selectorder",
    name: "disloading",
    component: SelectOrder,
    props: {
      next: "/disloading/form",
      type: "order",
    },
  },

  {
    path: "/disloading",
    name: "disloading",
    component: Disloading,
  },
  {
    path: "/disloading/start/:id",
    name: "disloadingstart",
    component: DisloadingQuestionStart,
  },
  {
    path: "/disloading/product/:id",
    name: "disloadingprdocut",
    component: DisloadingProduct,
  },
  {
    path: "/disloading/badcylinder",
    name: "disloadingbadcylinder",
    component: DisloadingBadCylinder,
  },
  {
    path: "/disloading/end",
    name: "disloadingend",
    component: DisloadingQuestionEnd,
  },
  {
    path: "/pickup",
    name: "pickup",
    component: Pickup,
  },
  {
    path: "/pickup/start/:id",
    name: "pickupstart",
    component: PickupQuestionStart,
  },
  {
    path: "/pickup/selectlocation",
    name: "pickup",
    component: PickupSelectLocation,
  },
  {
    path: "/pickup/product/:id",
    name: "pickupprdocut",
    component: PickupProduct,
  },

  {
    path: "/pickup/summary",
    name: "pickupsummary",
    component: PickupSummary,
  },
  {
    path: "/pickup/badcylinder",
    name: "pickupbadcylinder",
    component: PickupBadCylinder,
  },
  {
    path: "/pickup/selectproduct",
    name: "pickupbadselectproduct",
    component: PickupSelectProduct,
  },

  {
    path: "/production",
    name: "pickup",
    component: Production,
  },
  {
    path: "/production/product/:id",
    name: "Productionprdocut",
    component: ProductionProduct,
  },

  {
    path: "/production/selectproduct",
    name: "Productionbadselectproduct",
    component: ProductionSelectProduct,
  },

  {
    path: "/storage",
    name: "storage",
    component: Storage,
  },
  {
    path: "/storage/product/:id",
    name: "storageproduct",
    component: StorageProduct,
  },
  {
    path: "/orders",
    name: "orders",
    component: Orders,
  },
  {
    path: "/cylinder/search",
    name: "cylindersearch",
    component: CylinderSearch,
  },
  {
    path: "/cylinder/search/serial",
    name: "cylindersearchserial",
    component: CylinderSearchSerial,
  },
  {
    path: "/cylinder/qrcode/:code",
    name: "cylindershow",
    component: CylinderShow,
  },
  {
    path: "/simpleadd",
    name: "simpleadd",
    component: SimpleaddIndex,
  },
  {
    path: "/simpleadd/select",
    name: "simpleadd",
    component: SimpleaddSelectProduct,
  },

  {
    path: "/simpleadd/show/:code",
    name: "simpleadd",
    component: SimpleaddSuccess,
  },
  {
    path: "/complaint/create",
    name: "ComplaintCreateCylidner",
    component: ComplaintCreate,
  },
  {
    path: "/complaint/search",
    name: "ComplaintSearch",
    component: ComplaintSearch,
  },
  {
    path: "/complaint/:id",
    name: "ComplaintCreateCylidner",
    component: ComplaintSuccess,
  },
  {
    path: "/complaint/done/:id",
    name: "ComplaintDoneCylidner",
    component: ComplaintDone,
  },
  {
    path: "/legalization",
    name: "LegalizationIndex",
    component: LegalizationIndex,
  },

  {
    path: "/legalization/create",
    name: "LegalizationCreate",
    component: LegalizationCreate,
  },

  {
    path: "/legalization/:id/edit",
    name: "LegalizationEdit",
    component: LegalizationEdit,
  },
  {
    path: "/legalization/:id/close",
    name: "LegalizationClose",
    component: LegalizationClose,
  },
  {
    path: "/legalization/:id/cylinder",
    name: "LegalizationCylinder",
    component: LegalizationCylinder,
  },
  {
    path: "/legalization/:id/addcylinder/:qrcode",
    name: "LegalizationAddCylinder",
    component: LegalizationAddCylinder,
  },
  {
    path: "/legalization/:id/item/:item_id",
    name: "LegalizationCylinderItem",
    component: LegalizationCylinderItem,
  },
  {
    path: "/legalization/:id/aliencylinder",
    name: "LegalizationAddAlienCylinder",
    component: LegalizationAddAlienCylinder,
  },
  {
    path: "/legalization/:id/menu",
    name: "LegalizationMenu",
    component: LegalizationMenu,
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});
export default router;
