<template>
  <div
    v-if="complaint"
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-betwee align-items-center"
      style="background: #dadada"
    >
      <router-link
        v-if="!$route.query.back"
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <button
        v-if="$route.query.back"
        @click="$router.go(-1)"
        class="btn btn-lg btn-secondary m-2"
      >
        <i class="bi bi-chevron-left"></i>
      </button>
      <h2 class="flex-fill m-0">Rozw. reklamacji</h2>
      <!-- <a href="#" class="btn btn-lg btn-danger flex-fill m-2">Butle z błędami (3)</a> -->
      <button
      :disabled="!item.wasValid || !item.restoredToUse"

        @click.prevent="doneComplaint"
        class="btn btn-lg btn-success m-2"
      >
        Rozwiąż
      </button>
    </div>

    <div
      v-if="complaint.order"
      class="bg-light p-2 d-flex justify-content-between mb-2 w-100"
    >
      <span class="col-9 me-2"
        >Reklamacja butli od klienta:<br />
        {{ complaint.order.title }}</span
      >
    </div>

    <div
      class="w-100 p-2 pb-5 flex-grow-1"
      style="overflow: auto"
    >
      <h3>
        Butla {{ complaint.cylinder ? complaint.cylinder.cylinder_qr_code : "nieoznakowana" }}
      </h3>
      <p>{{ complaint.product.name }}</p>
      <!-- <span class="text-danger mb-3 d-block" v-if="!complaint.cylinder">Pamiętaj. Musisz napisać na butli numer
                        reklamacji.</span> -->
      <p>
        Status:
        <span :class="complaint.status == 'close' ? 'text-success' : 'text-danger'">{{
          complaint.status == "close" ? "zamknięta" : "otwarta"
        }}</span>
      </p>
      <p>Użytkownik zgłaszający: {{ complaint.user ? complaint.user.name : "---" }}</p>
      <p>Klient: {{ complaint.location ? complaint.location.location_name : "---" }}</p>

      <div class="text-center">
        <h4 class="mt-5">Numer reklamacji</h4>
        <h3 style="font-size: 64px">
          {{
            complaint.complaint_number.substr(0, 3) + " " + complaint.complaint_number.substr(3, 6)
          }}
        </h3>
      </div>
      <div class="mb-3">
        <ul>
          <li
            class="py-0 my-1"
            v-for="(text, k) in complaint.checkboxes"
            :key="k"
          >
            {{ text }}
          </li>
        </ul>
        <span class="form-control">
          {{ complaint.description }}
        </span>
      </div>
      <div class="form-group mb-3">
        <label>Czy reklamacja była zasadna?</label>
        <select
          v-model="item.wasValid"
          class="form-control"
        >
          <option value="1">TAK</option>
          <option value="0">NIE</option>
        </select>
      </div>
      <div class="form-group mb-3">
        <label>Czy butla przywrócona do użycia?</label>
        <select
          v-model="item.restoredToUse"
          class="form-control"
        >
          <option value="1">TAK</option>
          <option value="0">NIE</option>
        </select>
      </div>
    </div>
  </div>
  <div
    class="p-4 text-center"
    v-else
  >
    <h2>Nie znaleziono</h2>
    <router-link
      class="btn btn-outline-secondary mt-4"
      to="/"
    >
      Wróc
    </router-link>
  </div>
</template>
<script>
import api from "@/api/myApi";
export default {
  name: "ComplaintCreate",
  computed: {},
  data: function () {
    return {
      complaint: null,
      item: {},
    };
  },
  mounted: function () {
    api.findComplaint(this.$route.params.id).then((resposne) => {
      this.complaint = resposne.data.data;
    });
  },
  methods: {
    doneComplaint: function () {
      this.$swal({
        title: "Chcesz rozwiązać reklamację?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Tak!",
      }).then((response) => {
        if (response.isConfirmed) {
          api.postResolveComplaint(this.complaint.complaint_id, this.item).then(() => {
            this.$swal("Udało się", "", "success");
            this.$router.push('/complaint/'+this.complaint.complaint_number)
          });
        }
      });
    },
  },
};
</script>
