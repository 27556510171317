<template>
    <div class="d-inline-block">
        <CameraScanner @input="submitCode"></CameraScanner>
        <KeyboardScanner @input="submitCode" :listen="true"></KeyboardScanner>
        <div v-if="loading" class="loader-center text-center">
            <div class="spinner-grow" role="status">
            </div>
        </div>


    </div>
</template>
<script>

import KeyboardScanner from '../../components/KeyboardScanner.vue'
import CameraScanner from '../../components/CameraScanner.vue'
import api from '../../api/myApi';

export default {
    name: 'StoreCylinderScanner',
    components: {
        CameraScanner,
        KeyboardScanner,
    },
    data: function () {
        return {
            loading: false,
        }
    },
    methods: {
        submitCode: function (code) {
            let vm = this;
            this.loading = true;
            api.findCylinder(code).then(response => {
                console.log(response);
                let cylinder = vm.$store.getters['zaladunek/getCylinder'](response.data.cylinder_id);
                if (cylinder) {
                    vm.$toast.warning("Butla już zeskanowana")
                } else {
                    vm.$toast.success("Zeskanowano pomyślnie");
                    vm.$store.commit('zaladunek/cylinderAdd', response.data);

                    let cylinder_fit = vm.$store.getters['zaladunek/getProduct'](response.data.product_id);
                    if (!cylinder_fit) {
                        vm.$toast.warning("Butla nie pasuje do zamówień")
                    }
                    this.$store.dispatch('zaladunek/validate');
                }
                vm.loading = false;
            }, error => {
                console.log(error);
                vm.$toast.error("Nie znaleziono butli")
                vm.loading = false;
            })
        }
    }
}
</script>