<template>
    <div v-if="location"
        class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/pickup" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <!-- <button @click="cancelPickup" class="btn btn-lg btn-secondary m-2"><i class="bi bi-x-octagon"></i></button> -->
            <a v-if="$store.state.pickup.valid" type="button" @click.prevent="doPickup" href="/pickup/summary"
                class="btn btn-lg btn-success flex-fill m-2">Przyjmij</a>
        </div>
        <div class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
            <span class="col-9 me-2">Odbiór od:<br> {{ location.location_name }}</span>
        </div>
        <div v-if="complaintCylinders.length > 0" class="text-start w-100 p-2">
            <h2>Reklamacje:</h2>
            <div v-for="item, k in complaintCylinders" :key="k">
                <div @click="showInfoModal(item)"
                    :class="$store.getters['pickup/isLastScannedCylinder'](item.cylinder_id) ? 'bg-lightgreen' : 'bg-light'"
                    class=" p-2  mb-0  ">
                    <!-- style content how ever you like -->
                    <div class="py-2"> #{{ item.cylinder_qr_code }}</div>
                </div>

            </div>
        </div>
        <div v-if="legalizationCylinders.length > 0" class="text-start w-100 p-2">
            <h2>Legalizacje:</h2>
            <div v-for="item, k in legalizationCylinders" :key="k">
                <div @click="showInfoModal(item)"
                    :class="$store.getters['pickup/isLastScannedCylinder'](item.cylinder_id) ? 'bg-lightgreen' : 'bg-light'"
                    class=" p-2  mb-0  ">
                    <!-- style content how ever you like -->
                    <div class="py-2"> #{{ item.cylinder_qr_code }}</div>
                </div>

            </div>
        </div>
        <div class="text-start w-100 p-2">
            <h2>Towary przyjęte:</h2>
        </div>
        <div class="w-100 p-2 pb-5 flex-grow-1" style="overflow: auto;">
            <span :to="'/pickup/product/' + item.product_id" v-for="item, key in items" :key="key"
                class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center bg-light">
                <span class="flex-fill pe-2">{{ item.name }} </span>

                <span
                    :class="{ 'bg-danger': $store.getters['pickup/getErrors']({ product_id: item.product_id }).length > 0, 'bg-warning': $store.getters['pickup/getWarnings']({ product_id: item.product_id }).length > 0 }"
                    class=" bg-secondary col-3 badge fs-5 d-flex align-items-center justify-content-center">{{
                        $store.getters['pickup/countCylinder'](item.product_id)
                    }} </span>
            </span>



        </div>
        <!-- Magazyn żródłowy: {{ locationFrom.location_name }} -->
        <!-- <router-link to="/pickup/selectproduct"
            style="width: 50px; height: 50px; background: #dadada; border-radius: 5px; font-weight: bold; position: fixed; right: 10px; bottom: 10px;z-index:1000"
            class="d-flex justify-content-center align-items-center fs-1 text-dark">
            <i class="bi bi-plus"></i>
        </router-link> -->
        <!-- {{ $store.state.zaladunek.cylinders.length }}/ {{ iloscBulti }}< -->
        <CylinderModal errors-store="pickup" v-model="showItem"></CylinderModal>

    </div>
</template>
<script>
// import myApi from '@/api/myApi'

import CylinderModal from '../../components/CylinderModal.vue'

import api from '@/api/myApi';
// import CylinderListElement from '../components/CylinderListElement.vue'
import dictionary from '@/helpers/dictionary';
export default {
    name: 'PikcupView',
    components: {
        CylinderModal
    },
    props: {
        msg: String,
    },
    data: function () {
        return {
            submitCode: '',
            location_id: 0,
            orders: [],
            iloscBulti: 0,
            iloscBultiinOrders: 0,
            skantext: '',
            loadTowary: [],
            cylinders: [], //zeskanowowane butle
            inne: [], //produkty ktore sa złe
            pojazd_id: 0,
            listaQrcodowDoOPrzyjecia: [],
            showItem: null,


        }
    },
    computed: {
        location: function () {
            return this.$store.state.pickup.locationFrom;
        },
        items: function () {
            return this.$store.state.pickup.products;
        },
        complaintCylinders: function () {
            return this.$store.getters['pickup/getComplaintCylinders']
        },
        legalizationCylinders: function () {
            return this.$store.getters['pickup/getLegalizationCylinders']
        },
        // locationFrom: function () {
        //     return this.$store.state.zaladunek.locationFrom ? this.$store.state.zaladunek.locationFrom : {};
        // },
        errorMsg: function () {
            return dictionary.errorMsgCylinderPickup;
        },
        badCylinders: function () {
            return this.$store.getters["pickup/getBadCylinder"];
        },
    },
    methods: {

        showInfoModal: function (item) {
            this.showItem = item;
        },
        doPickup: function () {
            let vm = this;
            this.$store.dispatch('pickup/validate').then(() => {
                this.$swal({
                    title: 'Chcesz napewno przyjąć towary?',
                    html: this.$store.state.pickup.warnings.length > 0 ? '<b>Uwagi:</b><br>' + Array.from(this.$store.state.pickup.warnings, it => it.info).join('<br> ') : '',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Wróć',
                    confirmButtonText: 'Tak, przyjmuję!'
                }).then(response => {
                    if (response.isConfirmed) {
                        api.postPickup(vm.$store.state.location_id, vm.$store.getters['pickup/getFullData']).then(() => {
                            vm.$store.dispatch('pickup/reset');
                            vm.$router.push('/');
                            this.$swal({
                                title: 'Przyjęto towary!',
                                icon: 'success',
                                confirmButtonText: 'Ok!'
                            })
                        }, () => {
                            vm.$swal('Ups..',
                                'Wystąpił błąd serwera',
                                'error')
                        })
                    }
                })
            }, () => {
                vm.$router.push('/pickup');
                vm.$swal('Ups..',
                    'Wystąpił błąd walidacji',
                    'error')
            });

        },


        cancelPickup: function () {

            this.$swal({
                title: 'Chcesz przerwać proces odbioru?',
                text: "Ta akcja wyczyści wszystko co zeskanowałeś do tej pory! Jest to nieodrwacalne",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Tak, przerywam!'
            }).then(response => {
                if (response.isConfirmed) {
                    this.$store.dispatch('pickup/reset');
                    this.$router.push('/');
                }
            })
            //
        },

    },
    created() {
        // if (this.$store.state.disloading.order && !this.$store.state.pickup.order) {
        //     this.$swal('Musisz najpierw zakończyć proces wydawania towaru aby przyjąć towar od klienta!').then(() => {
        //         this.$router.push('/');
        //     });
        // }
        // if (this.$store.state.zaladunek.locationFrom) {
        //     this.$swal('Musisz najpierw zakończyć proces ładowania towaru aby wydać towar do klienta!').then(() => {
        //         this.$router.push('/');
        //     });
        // }
        if (!this.$store.state.pickup.locationFrom) {
            this.$router.push('/pickup/selectlocation');
        } else {
            this.$store.dispatch('pickup/validate');
        }

        this.$store.dispatch('pickup/validate').then(() => { }, () => {
            this.$router.push('/pickup');
            this.$swal('Ups..',
                'Wystąpił błąd walidacji',
                'error')
        })

    },
    mounted() {
        this.pojazd_id = this.$store.state.pojazd_id;
        this.location_id = this.$store.state.location_id;
        //  this.orders = this.$store.state.orders;
        this.loadTowary = this.$store.state.loadTowary;
        // this.calculateInStock();
        // this.countIlosc()
    }
}
</script>

