<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Wybierz klienta</h2>
      <!-- <router-link
        to="/certification/add"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        + Nowa
      </router-link> -->
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="w-100">
        <div class="input-group  mb-3 ">
          <input
          type="text"
          placeholder="Wyszukaj"
          v-model="query"
          @input="load"
          class="form-control form-control-lg "
          />
          <router-link to="/certification/client/add" class="btn btn-outline-primary d-flex align-items-center">Dodaj</router-link>
        </div>
      </div>
      <div
        class="card mb-3 w-100"
        v-for="(item, key) in last"
        :key="key"
      >
        <div
          class="card-body"
          @click="choose(item)"
        >
          <h4>
            {{ item.nazwa }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";

export default {
  name: "SimpleaddIndex",
  components: {},
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      date: "",
      loading: false,
      showAll: false,
      query: "",
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
  },
  methods: {
    load: function () {
      myApi.getAllCustomer({ query: this.query }).then((response) => {
        this.last = response.data.data;
      });
    },
    choose: function (item) {
      this.$store.commit("certification/setClient", item);
      this.$router.push("/certification/add");
    },
  },
  created() {},
  mounted() {
    this.load();
  },
};
</script>
