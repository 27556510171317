export default {
    client_id: '',
    options: {},
    install(Vue, options) {
        this.options = options;
        let vm = this;
        if (!localStorage) {
            return
        }
        if (options.silent) {
            this.client_id = 'silent';
        }
        else {
            this.client_id = localStorage.getItem('magico-vue-monitor-client-id');
            if (!this.client_id) {
                this.client_id = this.makeid(16);
                localStorage.setItem('magico-vue-monitor-client-id', this.client_id);
            }
        }
        this.sendActive();
        if (!options.disablePing) {
            setInterval(function () {
                vm.sendActive();
            }, 300000);
        }
        Vue.prototype.$debug_id = this.client_id;
        Vue.prototype.$MagicoLog = function (trace = '', info = '') {
            vm.sendCustom(trace, info);
        }
        Vue.config.errorHandler = (err, vm, info) => {
            // err: error trace
            // vm: component in which error occured
            // info: Vue specific error information such as lifecycle hooks, events etc.
            // TODO: Perform any custom logic or log to server
            this.sendError(err, vm, info);
            console.error('[VUE ERROR]', err, info);
        };
        Vue.config.warnHandler = (err, vm, info) => {

            this.sendWarn(err, vm, info);
            console.error('[VUE WARN]', err, info);
        };
        window.addEventListener('error', (event) => {
            var log_info = `${event.type}: ${event.message}`;
            var log_trace = `${event.lineno}: ${event.colno} - ${event.filename}`;
            this.sendJsError(log_info, log_trace);
            console.log(event)
        });
    },
    makeid: function (length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    },
    sendActive: function () {
        if (this.options.disablePing || this.options.silent) {
            return;
        }
        var last_active = localStorage.getItem('magico-vue-monitor-active');
        if ((Date.now() - last_active) > 60000) {
            fetch(this.options.API_URL + 'api/v1/vuemonitor/ping', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'app_name': this.options.APP_NAME, 'app_key': this.options.APP_KEY, 'app_client_id': this.client_id })
            });
            localStorage.setItem('magico-vue-monitor-active', Date.now());
        }
    },
    sendError: function (err, vm, info) {
        console.log(err);
        fetch(this.options.API_URL + 'api/v1/vuemonitor/error/verror', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'app_name': this.options.APP_NAME, 'app_key': this.options.APP_KEY, 'log_user_id': this.client_id, 'log_trace': err.name + ': ' + err.message + ' ' + err.lineNumber + ':' + err.columnNumber + ' ' + err.fileName, 'log_info': info })
        });
    },
    sendJsError: function (log_info, log_trace) {
        fetch(this.options.API_URL + 'api/v1/vuemonitor/error/jserror', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'app_name': this.options.APP_NAME, 'app_key': this.options.APP_KEY, 'log_user_id': this.client_id, 'log_trace': log_trace, 'log_info': log_info })
        });
    },
    sendWarn: function (err, vm, info) {
        fetch(this.options.API_URL + 'api/v1/vuemonitor/error/vwarn', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'app_name': this.options.APP_NAME, 'app_key': this.options.APP_KEY, 'log_user_id': this.client_id, 'log_trace': err, 'log_info': info })
        });
    },
    sendCustom: function (trace = '', info = '') {
        fetch(this.options.API_URL + 'api/v1/vuemonitor/error/custom', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'app_name': this.options.APP_NAME, 'app_key': this.options.APP_KEY, 'log_user_id': this.client_id, 'log_trace': trace, 'log_info': info })
        });
    }

};

