<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/legalization" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
            <EventCylinderScanner v-if="!closed" :cylinders="cylinders" :legalization="legalization"></EventCylinderScanner>
            <h2 class="flex-fill m-0">Legalizacja</h2>

            <router-link v-if="!closed" :to="'/legalization/' + $route.params.id + '/aliencylinder'"
            
            class="btn btn-lg btn-secondary m-2 ">
            <i class="bi bi-plus"></i>
        </router-link>
            <router-link :to="'/legalization/' + $route.params.id + '/menu'" type="button"
                class="btn btn-lg btn-secondary  m-2">
                <i class="bi bi-three-dots-vertical"></i></router-link>

        </div>

        <div class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
            <span class="col-9 me-2">Legazlizacja {{ legalization.legalization_number }}</span>
        </div>
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start container"
            style="overflow: auto; ">
            <div class="d-flex w-100 px-2 border-bottom py-1">
                <div class="col-3 col-md-2 border-end px-2 text-center">
                    Kod QR
                </div>
                <div class="col d-none d-md-block col-md-1 border-end px-2 text-center">
                    Rok produkcji
                </div>
                <div class="col border-end px-2 text-center">
                    Numer seryj.
                </div>
                <div class="col border-end px-2 text-center">
                    Czynnik
                </div>
                <div class=" col-1 border-end px-2 text-center d-none d-md-block">
                Ciśnienie próby
                </div>
                <div class="col-1 border-end px-2 text-center d-none d-md-block">
                    Waga recz.<br> [kg]
                </div>
                <div class="col-1 border-end px-2 text-center d-none d-md-block">
                    Waga realna<br> [kg]
                </div>
                <div class="col-1 border-end px-2 text-center d-none d-md-block">
                    Ubytek<br> [kg]
                </div>

                <!--                                 
                                #{{ item.cylinder_qr_code }} <span v-if="item.cylinder_id == 0">Producent: {{ item.cylinder_producent
                            }} Rok: {{ item.cylinder_production_date }}  Nr:{{ item.cylinder_serial_number }}</span>
                                <div v-if="item.cylinder_id == 0" class="badge ms-2 rounded-pill bg-primary">obca</div> -->
            </div>
            <div class="w-100 my-swipeout">
                <swipe-list ref="list" class="rounded-0 mb-0" :items="cylinders" item-key="id"
                    @swipeout:click="showInfoModal">
                    <template v-slot="{ item }">
                        <!-- item is the corresponding object from the array -->
                        <!-- index is clearly the index -->
                        <!-- revealLeft is method which toggles the left side -->
                        <!-- revealRight is method which toggles the right side -->
                        <!-- close is method which closes an opened side -->
                        <div :class="$store.getters['disloading/isLastScannedCylinder'](item.cylinder_id) ? 'bg-lightgreen' : 'bg-light'"
                            class=" p-2  mb-0  py-3">
                            <!-- style content how ever you like -->
                            <div style="cursor:pointer" class="d-flex">
                                <div class="col-3 col-md-2 border-end px-2 text-center "
                                    :style="item.cylinder_id == 0 ? 'background-color:#ffcccc' : ''">
                                    {{ item.cylinder_id == 0 ? 'obca' : '#' + item.cylinder_qr_code }}
                                </div>
                                <div class="d-none d-md-block col col-md-1 border-end px-2 text-center">
                                    {{ item.cylinder_production_date }}
                                </div>
                                <div class="col border-end px-2 text-center">
                                    {{ item.cylinder_serial_number }}
                                </div>
                                <div class="col border-end px-2 text-center">
                                    {{ item.cylinder_working_factor.toUpperCase() }}
                                </div>
                                <div class="col-1 border-end px-2 text-center d-none d-md-block">
                                    {{ item.cylinder_pressure_test }}
                                </div>
                                <div class="col-1 border-end px-2 text-center d-none d-md-block">
                                    {{ Math.round(item.cylinder_weight / 10) / 100 }}
                                </div>
                                <div class="col-1 border-end px-2 text-center d-none d-md-block">
                                    {{ Math.round(item.cylinder_weight_real / 10) / 100 }}
                                </div>
                                <div class="col-1 border-end px-2 text-center d-none d-md-block">
                                    {{ Math.round((item.cylinder_weight - item.cylinder_weight_real) / 10) / 100 }}
                                </div>

                                <!--                                 
                                #{{ item.cylinder_qr_code }} <span v-if="item.cylinder_id == 0">Producent: {{ item.cylinder_producent
                            }} Rok: {{ item.cylinder_production_date }}  Nr:{{ item.cylinder_serial_number }}</span>
                                <div v-if="item.cylinder_id == 0" class="badge ms-2 rounded-pill bg-primary">obca</div> -->
                            </div>

                        </div>
                    </template>
                    <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
                    <!-- remove if you dont wanna have left swipe side  -->

                    <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
                    <!-- remove if you dont wanna have right swipe side  -->
                    <template v-slot:right="{ item }">
                        <button v-if="!closed" @click="deleteCylinder(item)" class="btn btn-outline-danger rounded-0">
                            USUŃ
                        </button>
                    </template>
                    <template v-slot:empty>
                        <div>

                        </div>
                    </template>
                </swipe-list>
                <div v-if="!closed" class="pt-4">
                    <i>
                        Zeskanuj butlę aby dodać ją do legalizacji
                    </i>
                </div>
            </div>
        </div>
        <router-link v-if="!closed" :to="'/legalization/' + $route.params.id + '/aliencylinder'"
            style="width: 50px; height: 50px; background: #dadada; border-radius: 5px; font-weight: bold; position: fixed; right: 10px; bottom: 10px;z-index:1000"
            class="d-flex justify-content-center align-items-center fs-1 text-dark">
            <i class="bi bi-plus"></i>
        </router-link>
    </div>
</template>
<script>
import { SwipeList } from 'vue-swipe-actions';
import api from '@/api/myApi';
import EventCylinderScanner from '@/components/legalization/EventCylinderScanner.vue';
export default {
    name: 'QuestionStart',
    components: {
        SwipeList,
        EventCylinderScanner
    },
    computed: {
        closed: function () {
            return this.legalization.legalization_status == 'close'
        }
    },
    data: function () {
        return {
            cylinders: [],
            legalization: {},
            loading: false,
        }
    },
    mounted: function () {
        this.load();
    },
    methods: {
        showInfoModal: function (item) {
            this.$router.push('/legalization/' + item.legalization_id + '/item/' + item.legalization_item_id);
        },
        confirm: function () {
            this.$store.commit('disloading/setOrder', this.order);
            this.$router.push('/disloading');
        },
        deleteCylinder: function (item) {
            this.$swal({
                title: 'Chcesz usunąc butlę?',
                text: "Jest to nieodrwacalne",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Tak, usuwam!'
            }).then(response => {
                if (response.isConfirmed) {
                    api.deleteLegalizationCylinder(this.$route.params.id, item.legalization_item_id).then(() => {
                        this.load();
                        this.$toast('Usunięto butlę');
                    })
                }
            })


        },
        load: function () {
            this.loading = true;
            api.getAllLegalizationItem(this.$route.params.id).then(response => {
                this.cylinders = response.data.data;
                this.loading = false;
            })
            api.getLegalization(this.$route.params.id).then(response => {
                this.legalization = response.data;
            })
        }
    }
}
</script>

<style>
.my-swipeout .swipeout-list-item {
    margin-bottom: 0;
}
</style>