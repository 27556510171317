<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/disloading" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Butle nie pasujące</h2>
        </div>

        <div class="w-100 p-2 pb-5 flex-grow-1" style="overflow: auto;">
            <swipe-list ref="list" class="rounded-0" :items="cylinders" item-key="id" @swipeout:click="showInfoModal">
                <template v-slot="{ item }">
                    <!-- item is the corresponding object from the array -->
                    <!-- index is clearly the index -->
                    <!-- revealLeft is method which toggles the left side -->
                    <!-- revealRight is method which toggles the right side -->
                    <!-- close is method which closes an opened side -->
                    <div :class="$store.getters['disloading/isLastScannedCylinder'](item.cylinder_id) ? 'bg-lightgreen' : 'bg-light'"
                        class=" p-2  mb-0  ">
                        <!-- style content how ever you like -->
                        <div> #{{ item.cylinder_qr_code }}</div>
                        <div
                            v-if="$store.getters['disloading/getErrors']({ cylinder_id: item.cylinder_id }).length > 0">
                            <div class=" text-danger"
                                v-for="error, ke in $store.getters['disloading/getErrors']({ cylinder_id: item.cylinder_id }) "
                                :key="ke"><i class="bi bi-exclamation-diamond-fill pe-1"></i>{{ errorMsg[error.type] }}
                            </div>
                        </div>
                        <div
                            v-if="$store.getters['disloading/getWarnings']({ cylinder_id: item.cylinder_id }).length > 0">
                            <div class=" text-warning"
                                v-for="warning, ke in $store.getters['disloading/getWarnings']({ cylinder_id: item.cylinder_id }) "
                                :key="ke"><i class="bi bi-exclamation-diamond-fill pe-1"></i>{{
    errorMsg[warning.type]
                                }}</div>
                        </div>
                    </div>
                </template>
                <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
                <!-- remove if you dont wanna have left swipe side  -->

                <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
                <!-- remove if you dont wanna have right swipe side  -->
                <template v-slot:right="{ item }">
                    <button @click="deleteCylinder(item)" class="btn btn-outline-danger rounded-0">
                        USUŃ
                    </button>
                </template>
                <template v-slot:empty>
                    <div>

                    </div>
                </template>
            </swipe-list>


        </div>
        <CylinderModal errors-store="disloading" v-model="showItem"></CylinderModal>
    </div>

</template>
<script>
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { SwipeList } from 'vue-swipe-actions';
import CylinderModal from '../../components/CylinderModal.vue'
import dictionary from '@/helpers/dictionary.js'

export default {
    name: "LoadingItem",
    computed: {

        cylinders: function () {
            return this.$store.getters["disloading/getBadCylinder"];
        },
        isUnsigned: function () {
            return this.product.quantityEmpty || this.product.quantityFull;
        },
        errorMsg: function () {
            return dictionary.errorMsgCylinderDisloading;
        }
    },
    data: function () {
        return {
            showItem: null,
            showUnsigned: false,

        }
    },
    methods: {
        showInfoModal: function (item) {
            this.showItem = item;
        },
        deleteCylinder: function (item) {
            this.$store.commit('disloading/cylinderDel', item);
            if (this.cylinders.length == 0) {
                this.$router.push('/disloading');
            }
        }
    },
    components: {
        SwipeList,
        CylinderModal
    }
}
</script>
<style>
.swipeout {
    border-radius: 0px !important;
    box-shadow: 0 0 0 0;
}
</style>