import axios from 'axios';
import authAxios from './authAxios';
const myaxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

var apiUrl = process.env.VUE_APP_API_URL;
myaxios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
myaxios.defaults.timeout = 10000;

myaxios.interceptors.request.use(
    (config) => {
        const token = localStorage.access_token;
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token; // for Node.js Express back-end
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

myaxios.interceptors.response.use((response) => {
    return response
}, function (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && originalRequest.url === apiUrl + '/api/oauth/token') {
        alert('Server not working');
        return Promise.reject(error);
    }
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if (!localStorage.client_id || !localStorage.client_secret) {
            console.warn('try to get access token with no credentials')
            return Promise.reject(error);
        }
        return authAxios.getToken().then(res => {
            if (res.status === 200) {
                localStorage.setItem('access_token', res.data.access_token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
                originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access_token;
                return axios(originalRequest);
            }
        })
    }
    return Promise.reject(error);
});

export default myaxios;