<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative text-start">


        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link :to="'/legalization/' + $route.params.id + '/cylinder'" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Dodaj butle</h2>
            <button @click="confirm" type="button" class="btn btn-lg btn-success flex-fill m-2">
                Zapisz</button>
        </div>


        <div class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start container"
            style="overflow: auto; ">
            <div class="w-100">
                <h1>Butla obca</h1>



                <LegalizationCylinderForm v-model="cylinder" :validation="validation"></LegalizationCylinderForm>


                <CylinderInfo v-show="false" :cylinder="cylinder"></CylinderInfo>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api/myApi';
import LegalizationCylinderForm from '@/components/legalization/LegalizationCylinderForm.vue'
import dayjs from 'dayjs'
export default {
    name: 'LegalistaionAddCylinder',
    components: {
        LegalizationCylinderForm
    },
    computed: {

    },
    data: function () {
        return {
            cylinder: { cylinder_weight_real: 0, cylinder_pice: 'ce', cylinder_legalization_date: '' },
            legalization: null,
            validation: {

            },
            newDate: null,
            realWeight: 0,
        }
    },
    mounted: function () {
        this.load();
        this.cylinder.cylinder_legalization_date = dayjs().add(10, 'year').format('YYYY-MM-DD')

    },
    methods: {

        confirm: function () {
            this.validation = {};
            this.cylinder.cylinder_id = 0;
            this.cylinder.legalization_id = this.$route.params.id;
            api.postLegalizationAlienCylinder(this.$route.params.id, this.cylinder).then(() => {
                this.$router.push('/legalization/' + this.$route.params.id + '/cylinder');
                this.$toast('Zapisano poprawnie')
            }, err => {
                this.validation = err.response.data.messages
            })
        },
        deleteCylinder: function (item) {
            this.$store.commit('disloading/cylinderDel', item);
        },
       
        load: function () {
            // api.getLegalizationItem(this.$route.params.id, this.$route.params.item_id).then(response => {
            //     this.cylinder = response.data;
            // })
        }
    }
}
</script>