<template>
    <div class="d-inline-block">
        <CameraScanner @input="submitCode"></CameraScanner>
        <KeyboardScanner @input="submitCode" :listen="true"></KeyboardScanner>
        <div v-if="loading" class="loader-center text-center">
            <div class="spinner-grow" role="status">
            </div>
        </div>


    </div>
</template>
<script>

import KeyboardScanner from '../../components/KeyboardScanner.vue'
import CameraScanner from '../../components/CameraScanner.vue'
import api from '../../api/myApi';

export default {
    name: 'EventCylinderScanner',
    props: ['cylinders', 'legalization'],
    components: {
        CameraScanner,
        KeyboardScanner,
    },
    data: function () {
        return {
            loading: false,
        }
    },
    methods: {
        submitCode: function (code) {
            let vm = this;
            this.loading = true;
            api.findCylinder(code).then(response => {

                if (this.cylinders.find(item => item.cylinder_id == response.data.cylinder_id)) {
                    this.$toast('Butla jest już zeskanowana');
                    vm.loading = false;
                    return;
                }
                vm.$router.push('/legalization/' + vm.legalization.legalization_id + '/addcylinder/' + response.data.cylinder_qr_code)
                console.log(response);
                vm.loading = false;
            }, error => {
                console.log(error);
                vm.$toast.error("Nie znaleziono butli")
                vm.loading = false;
            })
        }
    }
}
</script>