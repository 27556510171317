<template>
    <div class="about text-center pt-4">
        <h1>Zakonczono zamówienie</h1>
        <div class="text-success pt-4">
            <span style="font-size:160px"> <i class="bi bi-emoji-laughing"></i></span>
            <button @click="closeOrder()" class="btn btn-outline-secondary mt-4 w-100">Koniec</button>
        </div>
        <footer class="footer text-end  px-4" style="margin-left: -15px;">
        </footer>
    </div>
</template>
<script>
export default {
    name: 'SummaryEnd',
    components: {},
    data: function () { return {} },
    props: {},
    computed: {},
    methods: {
        closeOrder() {
            this.$router.push('/');
        }
    },
    created() {
        this.order = this.$store.state.order;
    },
    mounted() {
        this.$store.commit('resetOrder', {});
        this.$store.commit('pickup/start', false);
        this.$store.commit('disloading/start', false);
        this.$store.commit('disloading/cylinderReset')
        this.$store.commit('pickup/cylinderReset')
        this.$store.commit('resetOrder');
    }
}
</script>