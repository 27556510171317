<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">
        <div class="w-100 d-flex justify-content-between" style="background: #dadada">
            <router-link to="/disloading" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
        </div>
        <div class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-center flex-column justify-content-center text-center"
            style="
            overflow: auto; ">
            <h2 class=" mx-3 text-center mt-4">Czy chcesz rozpocząć proces odbioru towaru?</h2>
            <span>Odbiór do: {{ order.title }}</span>
            <a href="#" @click.prevent="confirm" class="btn btn-success btn-lg mt-4 p-4">Tak, rozpocznij!</a>
            <router-link to="/" class="btn btn-outline-secondary btn-lg mt-4">Anuluj</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'QuestionStart',
    computed: {
        order: function () {
            return this.$store.getters['storage/getOrder'](this.$route.params.id)
        }
    },
    methods: {
        confirm: function () {
            this.$store.commit('pickup/setOrder', this.order);
            this.$router.push('/pickup');
        }
    }
}
</script>