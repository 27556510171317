<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative text-start"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        :to="'/legalization/' + $route.params.id + '/cylinder'"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Dodaj butle</h2>

      <button
        :disabled="
          this.cylinder.cylinder_weight_real <
          cylinder.cylinder_weight / 1000 - 0.1 * (cylinder.cylinder_weight / 1000)
        "
        @click="confirm"
        type="button"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        Zapisz
      </button>
    </div>

    <div
      class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start container"
      style="overflow: auto"
    >
      <div class="w-100">
        <h1>Butla {{ cylinder.cylinder_qr_code }}</h1>
        <LegalizationCylinderForm
          v-model="cylinder"
          :validation="validation"
        ></LegalizationCylinderForm>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/myApi";
import dayjs from "dayjs";
import LegalizationCylinderForm from "@/components/legalization/LegalizationCylinderForm.vue";
export default {
  name: "LegalistaionAddCylinder",
  components: {
    LegalizationCylinderForm,
  },
  computed: {},
  data: function () {
    return {
      cylinder: null,
      legalization: null,
      newDate: null,
      realWeight: 0,
      validation: {},
    };
  },
  mounted: function () {
    this.load();
  },
  methods: {
    confirm: function () {
      api
        .postLegalizationCylinder(this.$route.params.id, {
          legalization_id: this.$route.params.id,
          cylinder_id: this.cylinder.cylinder_id,
          cylinder_weight_real: this.cylinder.cylinder_weight_real,
          cylinder_legalization_date: this.cylinder.cylinder_legalization_date,
        })
        .then(() => {
          this.$router.push("/legalization/" + this.$route.params.id + "/cylinder");
          this.$toast("Zapisano poprawnie");
        });
    },
    deleteCylinder: function (item) {
      api.deleteLegalizationCylinder(this.$route.params.id, item.legalization_item.id);
    },
    getWeight: function () {
      api.getRandomWeight().then((response) => {
        console.log("here", dayjs().unix() - response.data.data.time);
        if (dayjs().unix() - response.data.data.time > 60) {
          this.$swal({
            icon: "warning",
            title: "Brak nowszego odczytu z wagi",
            text: "Ostatni odczyt " + dayjs(response.data.data.time*1000).format("YYYY-MM-DD HH:mm:ss"),
          });
        }
        this.cylinder.cylinder_weight_real = response.data.data.weight / 1000;
      });
    },
    load: function () {
      api.findCylinder(this.$route.params.qrcode).then((response) => {
        this.cylinder = response.data;
        this.cylinder.cylinder_weight_real = 0;
        this.cylinder.cylinder_legalization_date = dayjs().add(10, "year").format("YYYY-MM-DD");
      });
    },
  },
};
</script>
