<template>
    <div class="modal" :id="id">
        <div class="modal-dialog" :class="class_other">
            <div v-if="modal" class="modal-content">
                <div v-if="title" class="modal-header">
                    <h4 class="modal-title" v-html="title"></h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
    props: ['value', 'title', 'class_other'],
    data: function() {
        return {
            id: 'modal' + Math.round(Math.random() * 100000),
            modal: null,
        }
    },
    mounted() {
        this.modal = new Modal(document.getElementById(this.id))
        let vm = this;
        document.getElementById(this.id).addEventListener('hidden.bs.modal', function() {
            vm.triggerModal(false);

        })
        this.triggerModal(this.value,false);
    },
    methods: {
        triggerModal: function(value,emit=true) {
            if (value == true) {
                this.modal.show();
            } else {
                this.modal.hide()
                if(emit){
                    this.$emit('hide');
                }
            }
            this.$emit('input',value);
        }
    },
    watch: {
        value: function() {
            this.triggerModal(this.value);
        }
    }
}
</script>