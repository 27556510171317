<template>
    <div class="d-block">
        <div class="d-block" @click="modal.show(); pin = ''; focusInput(); $emit('open')">
            <slot></slot>
        </div>
        <div class="modal fade" :ref="'modal' + key" :id="'modal' + key" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 mb-4">
                                <input placeholder="Podaj kod butli" type="text" v-model="pin" id="input_number"
                                    class="form-control text-center fs-1">
                            </div>
                            <div v-for="i in 9" class="col-4 mb-2  " :key="i">
                                <button @click.prevent="pin = pin + i" class="btn btn-primary w-100 fs-1 py-3">{{
                                    i
                                }}</button>
                            </div>
                            <div class="col-4 mb-3  ">
                                <button @click.prevent="pin = pin + 0"
                                    class="btn btn-primary w-100 fs-1 py-3">0</button>
                            </div>
                            <div class="col-4 mb-3  ">
                                <button @click.prevent="pin = pin + 'W'"
                                    class="btn btn-primary w-100 fs-1 py-3">W</button>
                            </div>
                            <div class="col-4 mb-3  ">
                                <button @click.prevent="pin = pin + 'Z'"
                                    class="btn btn-primary w-100 fs-1 py-3">Z</button>
                            </div>
                            <div class="col-4 mb-3 ">
                                <button @click="modalX" class="btn btn-danger w-100 fs-1 py-2"><span
                                        class="material-icons" style="position: relative; top: 7px; font-size: 60px;">
                                        clear
                                    </span></button>
                            </div>
                            <div class="col-8 mb-3 ">
                                <button @click.prevent="modalOk()" class="btn btn-success w-100 fs-1 py-2"><span
                                        class="material-icons" style="position: relative; top: 7px; font-size: 60px;">
                                        check_circle_outline
                                    </span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';

export default {
    name: 'PinpadScanner',
    props: {},
    data: function () {
        return {
            code: '',
            pin: '',
            key: (Math.random() + 1).toString(36).substring(7),
            listener: null,
            data: {},
            modal: null,
        }
    },
    computed: {

    },

    methods: {
        modalX: function () {
            if (this.pin) {
                this.pin = ''
            } else {
                this.$emit('hide');
                this.modal.hide();

            }

        },
        modalOk: function () {
            if (!this.pin) {
                this.$emit('hide');
                this.modal.hide();
                return;
            }
            this.$emit('input', this.pin);
            this.pin = '';
            this.modal.hide();
            this.$emit('hide');
        },
        focusInput: function () {
            document.getElementById('input_number').focus();
        }

    },
    watch: {
        value: function () {
            /*  if (this.value) {
                  this.modal.show();
              } else {
                  this.modal.hide();
              }*/
        },
        cylinder: function () {
            this.data = this.cylinder;
        },

    },
    mounted() {
        this.data = this.cylinder;
        this.modal = new Modal(this.$refs['modal' + this.key])
        document.getElementsByTagName('body')[0].appendChild(this.$refs['modal' + this.key])
        let vm = this;
        this.$refs['modal' + this.key].addEventListener('hidden.bs.modal', function () {
            vm.$emit('hide')
        })


        if (this.value) {
            this.modal.show();
        }

    },
    beforeDestroy: function () {
        document.getElementById('modal' + this.key).remove();
    }
}
</script>