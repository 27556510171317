<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/production" class="btn btn-lg btn-secondary m-2"><i
                    class="bi bi-chevron-left"></i></router-link>
            <h2 class="flex-fill m-0">Wybierz produkt</h2>
            <a href="#" class="px-4 text-light" @click.prevent="showAll=!showAll">.</a>
        </div>
        <div class="w-100 p-3 pb-5 flex-grow-1" style="overflow: auto;">
            <input type="text" v-model="filter" class="form-control mb-2" placeholder="Wyszukaj">
            <div v-for="item, key in products" :key="key">
                <a v-if="shouldShow(item)" @click="addProduct(item)"
                    class="bg-light p-2 text-dark d-flex justify-content-between mb-2">
                    <div class="col-9 pe-2 d-flex justify-content-between">{{ item.name }}
                        <div v-if="item.available">
                            <span class="badge bg-success">W magazynie</span>
                        </div>
                    </div>
                    <span class="col-3"><button class="btn btn-primary w-100">Wybierz</button></span>
                </a>
            </div>
            <div class="w-100 text-center mt-4">
                <a @click.prevent="notAvailableInfo" href="#">Mojego produktu nie ma na liście</a>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    name: 'DisloadingSelectOrder',
    components: {},
    data: function () {
        return {
            selectedOrder: {},
            id: 0,
            products: [],
            filter: '',
            showAll: false,
        }
    },
    props: {
    },
    computed: {

    },
    methods: {
        addProduct: function (item) {
            this.$store.commit('production/addProduct', item);
            this.$router.push('/production');
        },
        notAvailableInfo: function () {
            this.$swal({
                icon: 'info',
                text: 'Wyświetlanę są tutaj tylko te produkty które są na stanie magazynu z którego prowadzisz proces produkcji'
            })
        },
        shouldShow: function (item) {
            if (this.showAll) {
                return (this.filter == '' || item.name.toLowerCase().includes(this.filter.toLowerCase()))
            } else {
                return (this.filter == '' || item.name.toLowerCase().includes(this.filter.toLowerCase())) && item.available
            }
        }
    },
    created() {
        this.products = JSON.parse(JSON.stringify(this.$store.state.storage.productDictionary));
        this.products.forEach((item) => {
            item.available = this.$store.getters['storage/isInStorage'](item.product_id)
        });
        this.products.sort((a, b) => { return (a.available < b.available) ? 1 : -1 })
    },
    mounted() {


    }
}
</script>