<template>
    <div>
        <button v-if="!custom" class="btn btn-lg btn-secondary m-2" @click="scan"><i
                class="bi bi-qr-code-scan"></i></button>
        <div @click="scan">
            <slot v-if="custom"></slot>
        </div>
        <div v-show="scanning" class="video_fixed">
            <video class="w-100" style="height: 100%;" autoplay id="video"></video>
            <div class="square"></div>
            <div class="scanbuttons d-flex w-100">
                <button @click="closeScanMode" class="btn btn-secondary w-50 fs-1 border-0">Anuluj</button>
                <PinpadScanner @input="sumbitPinpad" class="w-50"> <button class="btn btn-warning w-100 fs-1 border-0">Podaj kod
                        ręcznie</button>
                </PinpadScanner>
            </div>
        </div>
    </div>
</template>
<script>
import QrScanner from '../../node_modules/qr-scanner/qr-scanner.min.js'; // if installed via package and bundling with a module bundler like webpack or rollup
import PinpadScanner from './PinpadScanner.vue';
export default {
    name: 'CameraScenner',
    components: {
        PinpadScanner
    },
    props: {
        custom: Boolean,
    },
    data: function () {
        return {
            code: '',
            listener: null,
            qrScanner: null,
            scanning: false,
        }
    },
    computed: {

    },
    watch: {},
    methods: {
        scan: function () {

            this.scanning = true;
            this.qrScanner.start();
        },
        closeScanMode: function () {
            this.qrScanner.stop();
            this.scanning = false;
        },
        sumbitPinpad(pin) {
            this.$emit('input', pin);
            this.qrScanner.stop();
            this.scanning = false;
        },
    },
    mounted() {
        let vm = this;
        console.log(this.$refs.video);
        this.qrScanner = new QrScanner(document.getElementById('video'), result => {
            if (result) {

                vm.$emit('input', result.data);
                vm.qrScanner.stop();
                vm.scanning = false;
            }
            console.log(result)
        }, {
            maxScansPerSecond: 1
        });
    },
    beforeDestroy: function () { }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.video_fixed {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
}

.square {
    position: fixed;
    bottom: calc(50% - 150px);
    right: calc(50% - 150px);
    border: 6px solid red;
    height: 300px;
    width: 300px;
    z-index: 1050;
}

.scanbuttons {
    position: fixed;
    z-index: 1050;
    bottom: 0;
}
</style>