<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/certification"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Podgląd cert.</h2>
      <button
        v-if="printer_id"
        :disabled="printDisabled"
        class="btn btn-lg btn-secondary me-2"
        @click.prevent="print"
      >
        <i class="bi bi-printer"></i>
      </button>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <h5>#{{ item.cylinder?.cylinder_qr_code }}</h5>
      <div>Numer seryjny: {{ item.cylinder?.cylinder_serial_number }}</div>
      <div>Magazyn przyjęcia: {{ item.location?.location_name }}</div>
      <hr />
      <div>Klient: {{ item.customer?.nazwa }}</div>
      <div>Data: {{ item.created_at.date.substr(0, 16) }}</div>
      <div>
        Status: <span class="badge bg-primary">{{ item.status }}</span>
      </div>
      <div
        class="my-4"
        v-if="item.items.length > 1"
      >
        <h4>Inne butle w tym procesie:</h4>

        <div
          v-for="(it, key) in item.items"
          :key="key"
        >
          <div
            class="my-1 fs-5"
            v-if="it.certification_requests_id != item.certification_requests_id"
          >
            <router-link :to="'/certification/show/' + it.certification_requests_id"
              >#{{ it.cylinder.cylinder_qr_code }}</router-link
            >
          </div>
        </div>
      </div>
      <a
        class="btn btn-outline-secondary my-4"
        target="_blank"
        :href="
          'https://apps.alterct.com/blueapp/certificationrequests/' + $route.params.id + '/pdf'
        "
        >Drukuj potwierdzenie</a
      >
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";

export default {
  name: "SimpleaddIndex",
  components: {},
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      item: {},
      date: "",
      loading: false,
      showAll: false,
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
    printer_id: function () {
      return localStorage.getItem("printer_id") ?? null;
    },
  },
  methods: {
    print: function () {
      let vm = this;
      this.$swal
        .fire({
          title: "Drukowanie etykiety",
          text: "Domyślnie drukowana jest 1 etykieta",
          input: "select",
          inputOptions: {
            1: 1,
            2: 2,
            3: 3,
          },
          inputPlaceholder: "Ilość etykiet (1)",
          showCancelButton: true,
          cancelButtonText: "Anuluj",
        })
        .then((r) => {
          console.log(r);
          if (r.isConfirmed) {
            for (let i = 0; i < (r.value ? parseInt(r.value) : 1); i++) {
              myApi.printCertificationLabels(vm.item.certification_requests_id, vm.printer_id).then(
                () => {
                  console.log("print_success");
                },
                () => {
                  vm.$swal({
                    icons: "error",
                    title: "Błąd drukarki",
                    text: "Nie można było dodać zadania do kolejki druku",
                  });
                }
              );
            }
            vm.printDisabled = true;
            setTimeout(function () {
              vm.printDisabled = false;
            }, 10000);
            vm.$swal({
              icons: "success",
              title: "Wysłano do druku",
              text: "Drukarka w ciągu kilku sekund wydrukuje twoją etykietę",
            });
          }
        });
    },
  },
  created() {},
  mounted() {
    myApi.getCertification(this.$route.params.id).then((response) => {
      this.item = response.data;
    });
  },
};
</script>
