<template>
    <div class="flex-fill m-2">
        <button @click="modal = true" class="btn btn-lg btn-danger w-100 ">
            Błędy ({{
                $store.state.production.errors.length
            }})
        </button>
        <MagicoModal title="Błędy" v-model="modal">
            <div class="p-2">
                <ul class="list-group">
                    <li class="list-group-item" v-for="item, key in $store.state.production.errors" :key="key">{{
                        item.info
                    }}</li>
                </ul>
            </div>
        </MagicoModal>
    </div>
</template>
<script>
import MagicoModal from '../MagicoModal.vue';

export default {
    name: 'ValidationErrorsModal',
    components: {
        MagicoModal
    },
    data: function () {
        return {
            modal: false,
        }
    }

}
</script>