<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/cylinder/search"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Wyszukaj butle</h2>
    </div>

    <div class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
      <span class="col-12">Wprowadź numer seryjny butli</span>
    </div>

    <div
      class="w-100 p-0"
      style="overflow: auto; flex-grow: 2"
    >
      <div
        class="container"
        style="max-width: 720px"
      >
        <div class="row mx-0 mt-1">
          <div class="col-12 mb-1">
            <div class="input-group mb-0">
              <input
                v-model="pin"
                type="text"
                class="form-control fs-3"
                placeholder="Podaj nr seryjny"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary btn-lg"
                type="button"
                @click="search"
                id="button-addon2"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-3 w-100" v-if="firstLoaded">
          <h5>Znalezione butle:</h5>
          <i v-if="items.length==0">Nie znaleziono butli</i>
          <div class="w-100">
            <div
              v-for="(item, key) in items"
              :key="key"
              class="border-bottom w-100 p-3 rounded fs-5"
            >
              <router-link class="text-dark" :to="'/cylinder/qrcode/' + item.cylinder_qr_code">
                {{ item.cylinder_qr_code }}
                <div style="font-size:0.8rem" class="d-flex justify-content-between"> 
                 <div> {{ item.cylinder_name }} </div> <div> {{ item.cylinder_serial_number }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <KeyboardScanner
      :listen="true"
      @input="searchCylinder"
    ></KeyboardScanner>
  </div>
</template>

<script>
import KeyboardScanner from "@/components/KeyboardScanner.vue";
import myApi from "@/api/myApi";

export default {
  name: "CylinderSearch",
  components: { KeyboardScanner },
  data: function () {
    return {
      pin: "",
      items: [],
      firstLoaded: false,
    };
  },
  methods: {
    // modalOk: function () {
    //   this.$router.push("/cylinder/qrcode/" + this.pin);
    // },
    searchCylinder: function (code) {
      this.$router.push("/cylinder/qrcode/" + code);
    },
    search: function () {
      this.loading = true;
      myApi.getCylinderAll({ cylinder_serial_number: this.pin, limit: 10 }).then((r) => {
        this.items = r.data.data;
        this.firstLoaded = true;
      });
    },
  },
};
</script>

<style>
.btn-number {
  width: 60px;
  height: 60px;
}
</style>
