<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative text-start"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        :to="'/legalization/' + $route.params.id + '/cylinder'"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Zobacz butle</h2>
      <button
        v-if="cylinder.cylinder_id > 0 && closed"
        @click.prevent="refreshCylinder"
        class="btn btn-lg btn-secondary m-2"
      >
        <i class="bi bi-arrow-clockwise"></i>
      </button>
      <button
        v-if="closed"
        @click="confirm"
        type="button"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        Zapisz
      </button>
    </div>

    <div
      class="w-100 p-2 pb-5 flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start container"
      style="overflow: auto"
    >
      <div class="w-100">
        <h1>Butla {{ cylinder.cylinder_qr_code }}</h1>
        <LegalizationCylinderForm
          v-model="cylinder"
          :validation="validation"
          :legalization="legalization"
        >
        </LegalizationCylinderForm>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/myApi";

import LegalizationCylinderForm from "@/components/legalization/LegalizationCylinderForm.vue";
import dayjs from "dayjs";
export default {
  name: "LegalistaionAddCylinder",
  components: {
    LegalizationCylinderForm,
  },
  computed: {
    closed: function () {
      return this.legalization.legalization_status != "close";
    },
    alien: function () {
      return this.cylinder.cylinder_id == 0;
    },
  },
  data: function () {
    return {
      cylinder: {},
      legalization: {},
      newDate: null,
      realWeight: 0,
      validation: {},
    };
  },
  mounted: function () {
    this.load();
  },
  methods: {
    confirm: function () {
      if (!this.alien) {
        api
          .putLegalizationCylinder(
            this.$route.params.id,
            this.cylinder.legalization_item_id,
            this.cylinder
          )
          .then(
            () => {
              this.$router.push("/legalization/" + this.$route.params.id + "/cylinder");
              this.$toast("Zapisano poprawnie");
            },
            (err) => {
              this.validation = err.response.data.messages;
            }
          );
      } else {
        api
          .putLegalizationAlienCylinder(
            this.$route.params.id,
            this.cylinder.legalization_item_id,
            this.cylinder
          )
          .then(
            () => {
              this.$router.push("/legalization/" + this.$route.params.id + "/cylinder");
              this.$toast("Zapisano poprawnie");
            },
            (err) => {
              this.validation = err.response.data.messages;
            }
          );
      }
    },

    refreshCylinder: function () {
      if (!this.cylinder.cylinder_id) return;
      let vm = this;

      this.$swal({
        title: "Chcesz odświeżyc dane butli?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Anuluj",
        confirmButtonText: "Tak!",
      }).then((response) => {
        if (response.isConfirmed) {
          api.getCylinder(this.cylinder.cylinder_id).then((response) => {
            if (response.data) {
              let cylinder_weight_real = vm.cylinder.cylinder_weight_real;
              let cylinder_legalization_date = vm.cylinder.cylinder_legalization_date;
              let cylinder_comment = vm.cylinder.cylinder_comment;
              let legalization_id = vm.cylinder.legalization_id;
              let legalization_item_id = vm.cylinder.legalization_item_id;
              vm.cylinder = response.data;
              vm.cylinder.cylinder_weight_real = cylinder_weight_real;
              vm.cylinder.cylinder_legalization_date = cylinder_legalization_date;
              vm.cylinder.cylinder_comment = cylinder_comment;
              vm.cylinder.legalization_id = legalization_id;
              vm.cylinder.legalization_item_id = legalization_item_id;
            }
          });
        }
      });
    },
    deleteCylinder: function (item) {
      this.$store.commit("disloading/cylinderDel", item);
    },
    getWeight: function () {
      api.getRandomWeight().then((response) => {
        console.log("", dayjs().unix() - response.data.data.time);
        if (dayjs().unix() - response.data.data.time > 60) {
          this.$swal({
            icon: "warning",
            title: "Brak nowszego odczytu z wagi",
            text: "Ostatni odczyt " + dayjs(response.data.data.time*1000).format("YYYY-MM-DD HH:mm:ss"),
          });
        }
        this.cylinder.cylinder_weight_real = response.data.data.weight;
      });
    },
    load: function () {
      api
        .getLegalizationItem(this.$route.params.id, this.$route.params.item_id)
        .then((response) => {
          this.cylinder = response.data;
        });
      api.getLegalization(this.$route.params.id).then((response) => {
        this.legalization = response.data;
      });
    },
  },
};
</script>
