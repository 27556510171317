<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/certification"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h3 class="flex-fill m-0">Nowa cert.</h3>
      <button
        @click="cancel"
        class="btn btn-lg btn-secondary m-2"
      >
        <i class="bi bi-x-octagon"></i>
      </button>

      <button
        type="button"
        @click="add"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        Zapisz
      </button>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="w-100 d-block">
        <div class="w-100 mb-3 bg-body-tertiary border rounded-3 p-3 d-block mb-3 text-black">
          <div class=" ">
            <h5 v-if="client">{{ client.nazwa }}</h5>
            <div v-if="client">
              {{ client.ulica }} {{ client.nrdomu
              }}{{ client.nrlokalu ? "/" + client.nrlokalu : "" }}
              <br />
              {{ client.kodpocztowy }} {{ client.miasto }}<br />
              {{ client.nip ? "NIP: " + client.nip : "" }}
            </div>
            <router-link to="/certification/client"
              >{{ client ? "Zmień" : "Wybierz" }}
            </router-link>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Nr telefonu</label>
          <input
            v-model="phone"
            @change="updatePhone"
            type="tel"
            class="form-control"
          />
        </div>
        <hr />
        <h3>Lista butli:</h3>
        <i v-if="cylinders.length == 0">Kliknij "+" w prawym dolnym rogu, aby dodać butlę.</i>
        <router-link
          v-for="(item, key) in cylinders"
          :key="key"
          class="text-reset"
          :to="'/certification/cylinder/' + key"
        >
          <div class="card w-100 mb-3">
            <div class="card-body">
              <h5>#{{ item.cylinder_qr_code }}</h5>
              <div class="row">
                <div class="col-12">
                  Numer seryjny:
                  <span class="fw-bold">{{ item.cylinder_serial_number }}</span>
                </div>
                <div class="col-6">
                  Typ gazu:
                  <span class="fw-bold">{{ item.cylinder_working_factor }}</span>
                </div>
                <div class="col-6">
                  Waga:
                  <span class="fw-bold">{{ item.cylinder_weight }}</span>
                </div>
                <div class="col-6">
                  Pojemność:
                  <span class="fw-bold">{{ item.cylinder_capacity }}</span>
                </div>
                <div class="col-6">
                  Rok produkcji:
                  <span class="fw-bold">{{ item.cylinder_production_date }}</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>

        <router-link
          to="/certification/cylinder"
          style="
            width: 50px;
            height: 50px;
            background: #dadada;
            border-radius: 5px;
            font-weight: bold;
            position: fixed;
            right: 10px;
            bottom: 10px;
            z-index: 1000;
          "
          class="d-flex justify-content-center align-items-center fs-1 text-dark"
        >
          <i class="bi bi-plus"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";

export default {
  name: "SimpleaddIndex",
  components: {},
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      date: "",
      loading: false,
      phone: "",
      showAll: false,
      last: [],
    };
  },
  props: {},
  computed: {
    client: function () {
      return this.$store.state.certification.client;
    },
    cylinders: function () {
      return this.$store.state.certification.cylinders;
    },
    printer_id: function () {
      return localStorage.getItem("printer_id") ?? null;
    },
  },
  methods: {
    add: function () {
      this.$store.commit("certification/setPhone", this.phone);
      this.$swal({
        title: "Chcesz dodać zlecenie?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Tak, dodaj!",
      }).then((response) => {
        if (response.isConfirmed) {
          this.$store.dispatch("certification/createCertification").then(
            (r) => {
              this.$store.commit("certification/reset");
              this.proccessPrint(r.data);
            },
            (e) => {
              this.$swal("Wystąpił błąd", JSON.stringify(e.response.data.messages), "error");
            }
          );
        }
      });
    },
    proccessPrint: function (data) {
      let vm = this;
      if (!vm.printer_id) {
        if (data.data[0]) {
          vm.$router.push("/certification/show/" + data.data[0].certification_requests_id);
        } else {
          vm.$router.push("/certification/");
        }
        return;
      }
      this.$swal({
        title: "Czy chcesz wydrukować etykiety na butlę?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Tak, drukuj!",
      }).then((response) => {
        if (response.isConfirmed) {
          data.data.forEach((item) => {
            vm.print(item.certification_requests_id);
          });
        }
        setTimeout(function () {
          if (data.data[0]) {
            vm.$router.push("/certification/show/" + data.data[0].certification_requests_id);
          } else {
            vm.$router.push("/certification/");
          }
        }, 2000);
      });
    },
    print: function (id) {
      let vm = this;
      myApi.printCertificationLabels(id, this.printer_id).then(
        () => {
          console.log("print_success");
        },
        () => {
          vm.$swal({
            icons: "error",
            title: "Błąd drukarki",
            text: "Nie można było dodać zadania do kolejki druku",
          });
        }
      );
    },
    cancel: function () {
      this.$swal({
        title: "Chcesz anulować?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Wróć",
        confirmButtonText: "Tak, anuluj!",
      }).then((response) => {
        if (response.isConfirmed) {
          this.$store.commit("certification/reset");
          this.$router.push("/certification");
        }
      });
    },
    updatePhone: function () {
      this.$store.commit("certification/setClientPhone", this.phone);
    },
  },
  created() {},
  mounted() {
    const data = localStorage.getItem("last_simpleadd");
    if (data) {
      this.last = JSON.parse(data) ?? [];
    }
    if (!this.client) {
      this.$router.push("/certification/client");
    }
    this.phone = this.$store.state.certification.client_phone;
    if (this.client && !this.phone) {
      this.phone = this.client.telefon;
    }
  },
};
</script>
