<template>
  <div
    class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative"
  >
    <div
      class="w-100 d-flex justify-content-between align-items-center"
      style="background: #dadada"
    >
      <router-link
        to="/"
        class="btn btn-lg btn-secondary m-2"
        ><i class="bi bi-chevron-left"></i
      ></router-link>
      <h2 class="flex-fill m-0">Certyfikacja</h2>
      <router-link
        to="/certification/add"
        class="btn btn-lg btn-success flex-fill m-2"
      >
        + Nowa
      </router-link>
    </div>
    <div
      v-if="loading"
      class="text-center"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="w-100 p-2 pb-5 container flex-grow-1 d-flex align-items-start flex-column justify-content-start text-start"
      style="overflow: auto"
    >
      <div class="w-100">
        <router-link
          v-for="(item, key) in last"
          :key="key"
          class="bg-body-tertiary border rounded-3 p-3 d-block mb-3 text-black"
          :to="'/certification/show/' + item.certification_requests_id"
        >
          <div class="row fw-bold mb-2 mb-sm-1">
            <div class="col-6 col-sm-4">Nr butli:</div>
            <div class="col-6 col-sm-8">#{{ item.cylinder?.cylinder_qr_code }}</div>
          </div>

          <div class="row mb-2 mb-sm-1">
            <div class="col-12 col-sm-4">Klient:</div>
            <div class="col-12 col-sm-8">
              {{ item.customer?.nazwa }}
            </div>
          </div>

          <div class="row mb-2 mb-sm-1">
            <div class="col-6 col-sm-4 order-0 order-sm-0">Data dodania:</div>
            <div class="col-6 col-sm-8 order-2 order-sm-1">
              {{ item.created_at }}
            </div>
          </div>

          <div class="row mb-2 mb-sm-1">
            <div class="col-6 col-sm-4">Status</div>
            <div class="col-6 col-sm-8">
              <span class="badge bg-primary-subtle text-primary-emphasis rounded-pill">{{
                item.status
              }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import myApi from "@/api/myApi";

export default {
  name: "SimpleaddIndex",
  components: {},
  data: function () {
    return {
      selectedOrder: {},
      id: 0,
      products: [],
      filter: "",
      serial: "",
      date: "",
      loading: false,
      showAll: false,
      last: [],
    };
  },
  props: {},
  computed: {
    product: function () {
      return this.$store.state.simpleadd.product;
    },
  },
  methods: {},
  created() {},
  mounted() {
    const data = localStorage.getItem("last_simpleadd");
    if (data) {
      this.last = JSON.parse(data) ?? [];
    }

    myApi.getAllCertification().then((response) => {
      this.last = response.data.data;
    });
  },
};
</script>
