<template>
  <div>
    <div class="form-floating mb-3">
      <input
        placeholder="Nazwa lub znak wytwórcy"
        class="form-control form-control-lg"
        type="text"
        :disabled="!alien || closed"
        v-model="item.cylinder_producent"
        :class="{ 'is-invalid': validation.cylinder_producent }"
      />
      <div class="invalid-feedback">{{ validation.cylinder_producent }}</div>
      <label>Nazwa lub znak wytwórcy</label>
    </div>

    <div class="form-floating mb-3">
      <DatePicker
        :disabled="!alien || closed"
        format="YYYY"
        placeholder="Rok produkcji"
        value-type="YYYY"
        type="year"
        class="w-100"
        v-model="item.cylinder_production_date"
        :class="{ 'is-invalid': validation.cylinder_production_date }"
        :input-class="{
          'is-invalid': validation.cylinder_production_date,
          'form-control form-control-lg': true,
          'pt-4': item.cylinder_production_date,
          'py-3 fs-6 text-dark': !item.cylinder_production_date,
        }"
      >
      </DatePicker>
      <label
        v-if="item.cylinder_production_date"
        style="top: -10px; color: grey; font-size: 14px"
        >Rok produkcji</label
      >
      <div class="invalid-feedback">{{ validation.cylinder_production_date }}</div>
    </div>

    <div class="form-floating mb-3">
      <input
        placeholder="Numer fabryczny"
        class="form-control form-control-lg"
        type="text"
        :disabled="!alien || closed"
        v-model="item.cylinder_serial_number"
        :class="{ 'is-invalid': validation.cylinder_production_date }"
      />
      <div class="invalid-feedback">{{ validation.cylinder_production_date }}</div>
      <label>Numer fabryczny</label>
    </div>

    <div class="form-floating mb-3">
      <input
        placeholder=""
        class="form-control form-control-lg"
        type="text"
        :disabled="!alien || closed"
        v-model="item.cylinder_qr_code"
        :class="{ 'is-invalid': validation.cylinder_qr_code }"
      />
      <div class="invalid-feedback">{{ validation.cylinder_qr_code }}</div>
      <label>Nr ewidencyjny</label>
    </div>

    <div class="form-floating mb-3">
      <select
        placeholder="Czynnik roboczy"
        class="form-select"
        type="text"
        :disabled="!alien || closed"
        v-model="item.cylinder_working_factor"
        :class="{ 'is-invalid': validation.cylinder_working_factor }"
      >
        <option
          v-for="(it, key) in working_factor"
          :key="key"
          :value="it.value"
        >
          {{ it.name }}
        </option>
      </select>
      <label>Czynnik roboczy</label>
      <div class="invalid-feedback">{{ validation.cylinder_working_factor }}</div>
    </div>

    <div class="form-floating mb-3">
      <input
        placeholder="Pojemność"
        class="form-control form-control-lg"
        type="number"
        :disabled="!alien || closed"
        v-model="item.cylinder_capacity"
        :class="{ 'is-invalid': validation.cylinder_capacity }"
      />
      <div class="invalid-feedback">{{ validation.cylinder_capacity }}</div>
      <label>Pojemność [l]</label>
    </div>

    <div class="form-floating mb-3">
      <input
        placeholder="Ciśnienie robocze"
        class="form-control form-control-lg"
        type="number"
        :disabled="!alien || closed"
        v-model="item.cylinder_pressure_filling"
        :class="{ 'is-invalid': validation.cylinder_pressure_filling }"
      />
      <div class="invalid-feedback">{{ validation.cylinder_pressure_filling }}</div>
      <label>Ciśnienie robocze [bar]</label>
    </div>

    <div class="form-floating mb-3">
      <input
        placeholder=""
        class="form-control form-control-lg"
        type="number"
        :disabled="!alien || closed"
        v-model="item.cylinder_pressure_test"
        :class="{ 'is-invalid': validation.cylinder_pressure_test }"
      />
      <div class="invalid-feedback">{{ validation.cylinder_pressure_test }}</div>
      <label>Ciśnienie próbne [bar]</label>
    </div>

    <div class="form-floating mb-3">
      <input
        placeholder="Tara lub masa"
        class="form-control form-control-lg"
        type="number"
        :disabled="!alien || closed"
        v-model="item.cylinder_mass"
        :class="{ 'is-invalid': validation.cylinder_mass }"
      />
      <div class="invalid-feedback">{{ validation.cylinder_mass }}</div>
      <label>Tara lub masa [kg]</label>
    </div>

    <div class="form-floating mb-3">
      <WeightInput
        class="form-control form-control-lg"
        type="number"
        placeholder="Waga wybita [kg]"
        :disabled="!alien || closed"
        v-model="item.cylinder_weight"
      />
      <label>Waga wybita [kg]</label>
      <div class="invalid-feedback">{{ validation.cylinder_weight }}</div>
    </div>
    <div class="form-floating mb-3">
      <div class="input-group">
        <div class="form-floating">
          <WeightInput
            placeholder="Waga realna [kg]"
            :disabled="closed"
            v-model="item.cylinder_weight_real"
            type="number"
            class="form-control form-control-lg"
          />
          <label>Waga realna [kg]</label>
        </div>
        <button
          v-if="!closed"
          @click="getWeight"
          class="btn btn-outline-secondary"
        >
          Pobierz z wagi
        </button>
      </div>
      <div class="invalid-feedback">{{ validation.cylinder_weight_real }}</div>
    </div>
    <div class="form-floating mb-3">
      <input
        class="form-control form-control-lg"
        type="number"
        :class="{ 'is-invalid': item.cylinder_weight - item.cylinder_weight_real < 0 }"
        placeholder="Waga wybita [kg]"
        :disabled="true"
        :value="Math.round((item.cylinder_weight - item.cylinder_weight_real) / 10) / 100"
      />
      <label>Ubytek [kg]</label>
      <div class="invalid-feedback">{{ validation.cylinder_weight }}</div>
      <div class="text-danger" v-if="percentageUbytek>=2">Ubytek w tej butli jest większy niż 2%. Wynosi {{ percentageUbytek }}%</div>
    </div>
    <div class="form-floating mb-3">
      <DatePicker
        :disabled="closed"
        format="YYYY-MM"
        value-type="YYYY-MM-DD"
        type="month"
        class="w-100"
        v-model="item.cylinder_legalization_date"
        placeholder="Rok produkcji"
        :class="{ 'is-invalid': validation.cylinder_legalization_date }"
        :input-class="{
          'is-invalid': validation.cylinder_legalization_date,
          'form-control form-control-lg': true,
          'pt-4': item.cylinder_legalization_date,
          'py-3 fs-6 text-dark': !item.cylinder_legalization_date,
        }"
      >
      </DatePicker>
      <label
        v-if="item.cylinder_legalization_date"
        style="top: -10px; color: grey; font-size: 14px"
        >Ważność legalizacji</label
      >
      <div class="invalid-feedback">{{ validation.cylinder_legalization_date }}</div>
    </div>
    <div class="form-floating mb-3">
      <select
        class="form-select form-select-lg"
        :disabled="!alien || closed"
        type="text"
        v-model="item.cylinder_pice"
        :class="{ 'is-invalid': validation.cylinder_pice }"
      >
        <option value="pi">&Pi;</option>
        <option value="ce">CE</option>
      </select>
      <label>Znak</label>
      <div class="invalid-feedback">{{ validation.cylinder_pice }}</div>
    </div>

    <div class="form-floating mb-3">
      <textarea
        placeholder="Uwagi"
        :disabled="closed"
        class="form-control form-control-lg"
        type="number"
        v-model="item.cylinder_comment"
        :class="{ 'is-invalid': validation.cylinder_comment }"
      ></textarea>
      <div class="invalid-feedback">{{ validation.cylinder_comment }}</div>
      <label>Uwagi</label>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import api from "@/api/myApi";
import WeightInput from "../WeightInput.vue";
import dayjs from "dayjs";
export default {
  components: { DatePicker, WeightInput },
  name: "LegalizationCylinderForm",
  props: ["validation", "value", "legalization"],
  data: function () {
    return {
      item: {},
      working_factor: [],
    };
  },
  computed: {
    closed: function () {
      return this.legalization?.legalization_status == "close";
    },
    alien: function () {
      return this.item.cylinder_id == 0 || this.item.cylinder_id == null;
    },

    percentageUbytek: function () {
      return Math.round((1 - this.item.cylinder_weight_real / this.item.cylinder_weight) * 1000)/10;
    },
  },
  mounted() {
    this.loadField();
    api.getCylinderWorkingFactor().then((response) => {
      this.working_factor = response.data;
    });
  },
  watch: {
    value: function () {
      this.loadField();
    },
  },
  methods: {
    loadField: function () {
      this.item = this.value;
    },
    emitField: function () {
      this.$emit("input", this.item);
    },
    getWeight: function () {
      api.getRandomWeight().then((response) => {
        console.log("here", dayjs().unix() - response.data.data.time);
        if (dayjs().unix() - response.data.data.time > 60) {
          this.$swal({
            icon: "warning",
            title: "Brak nowszego odczytu z wagi",
            text:
              "Ostatni odczyt " +
              dayjs(response.data.data.time * 1000).format("YYYY-MM-DD HH:mm:ss"),
          });
        }
        this.item.cylinder_weight_real = response.data.data.weight;
        this.emitField();
      });
    },
  },
};
</script>
